import axios from 'src/utils/axios';
import imageToBlob from 'src/utils/imageToBlob';

export const postNewMaintenanceLog = async (newMaintenanceInfor) => {
  try {
    const formData = new FormData();
    formData.append('title', newMaintenanceInfor.title);
    formData.append('deviceId', newMaintenanceInfor.deviceData.id);
    formData.append('reporterId', newMaintenanceInfor.reporterId);
    formData.append('workerId', newMaintenanceInfor.workerId);
    formData.append('serviceType', newMaintenanceInfor.serviceType);
    formData.append('timeZone', newMaintenanceInfor.timeZone);
    formData.append('reportDate', newMaintenanceInfor.reportDate);

    if (newMaintenanceInfor.dispatchDate)
      formData.append('dispatchDate', newMaintenanceInfor.dispatchDate);

    if (newMaintenanceInfor.issueDescription)
      formData.append('issueDescription', newMaintenanceInfor.issueDescription);

    if (newMaintenanceInfor.actionTaken)
      formData.append('actionTaken', newMaintenanceInfor.actionTaken);

    if (newMaintenanceInfor.description)
      formData.append('description', newMaintenanceInfor.description);

    if (newMaintenanceInfor.maintenanceImage.length) {
      newMaintenanceInfor.maintenanceImage.forEach((eachImageData) => {
        formData.append(
          'maintenanceImage',
          imageToBlob(eachImageData.image),
          eachImageData.name,
        );
      });
    }

    const res = await axios.post('edmu/device/maintenance-log', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return res;
  } catch (error) {
    return error;
  }
};

export const getAllMaintenanceLog = async (requestInfor) => {
  const res = await axios.get('edmu/device/maintenance-log', {
    params: {
      'device-id': requestInfor?.deviceId || null,
      'report-date[start]': requestInfor?.reportStartDate || null,
      'report-date[end]': requestInfor?.reportEndDate || null,
      'dispatch-date[start]': requestInfor?.dispatchStartDate || null,
      'dispatch-date[end]': requestInfor?.dispatchEndDate || null,
      'time-zone': requestInfor?.timeZone || null,
      'sort-type': requestInfor?.sortType || null,
      limit: requestInfor?.limit || null,
    },
  });
  return res.data;
};

export const getMaintenanceLogById = async (requestInfor) => {
  const res = await axios.get('edmu/device/maintenance-log', {
    params: {
      id: requestInfor?.logId,
      'device-id': requestInfor?.deviceId || null,
      'report-date[start]': requestInfor?.reportStartDate || null,
      'report-date[end]': requestInfor?.reportEndDate || null,
      'dispatch-date[start]': requestInfor?.dispatchStartDate || null,
      'dispatch-date[end]': requestInfor?.dispatchEndDate || null,
      'time-zone': requestInfor?.timeZone || null,
      'sort-type': requestInfor?.sortType || null,
      limit: requestInfor?.limit || null,
    },
  });
  return res.data;
};

export const patchMaintenanceLog = async (modifyInfo) => {
  const res = await axios.patch(
    `edmu/device/maintenance-log?id=${modifyInfo.id}`,
    { ...modifyInfo.data, timeZone: modifyInfo.timeZone },
  );
  return res;
};

export const deleteMaintenanceLog = async (logId) => {
  const res = await axios.delete(`edmu/device/maintenance-log?id=${logId}`);
  return res;
};

export const postMaintenanceImage = async (dataInfor) => {
  const formData = new FormData();

  console.log(dataInfor.images);
  if (dataInfor.images?.length) {
    dataInfor.images.forEach((eachImageData) => {
      formData.append(
        'image',
        imageToBlob(eachImageData.image),
        eachImageData.name,
      );
    });
  }

  const res = await axios.post(
    `edmu/image/device-maintenance-log?type-id=${dataInfor.logId}`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );
  return res;
};

export const deleteMaintenanceImage = async (imageId) => {
  const res = await axios.delete(`edmu/image?id=${imageId}`);
  return res;
};
