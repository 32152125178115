import { Box, alpha, styled } from '@mui/material';

export const ThemeSettingsButtonBox = styled(Box)(
  ({ theme }) => `
    margin-right: 18px;
    cursor: pointer;

    .MuiBox-root {
        width: ${theme.spacing(4)};
        height: ${theme.spacing(4)};
        border-radius: ${theme.general.borderRadiusLg};
        background: ${alpha(theme.colors.primary.lighter, 0.8)};
        transition: ${theme.transitions.create(['background'])};
        &:hover {
            background: ${alpha(theme.colors.primary.lighter, 0.5)};
        };
        padding: 8px;
    }
    img {
        width: 20px;
        height: 20px;
    }
    `,
);
