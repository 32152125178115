import { makeAutoObservable } from 'mobx';
import { CONTROL_STATUS } from 'src/assets/constants';

class EdmuStore {
  alertStore;

  utilStore;

  deviceStore;

  devicesUnderControl = [];

  devicesUnderTimeout = [];

  agGridAction = {
    connectedGrdiRefreshState: false,
    unconnectedGrdiRefreshState: false,
    connectedDeviceId: null,
    unconnectedDeviceId: null,
    connectedGridPageForce: null,
    unconnectedGridPageForce: null,
    selectedDeviceData: null,
  };

  constructor({ alertStore, utilStore, deviceStore }) {
    makeAutoObservable(this);
    this.alertStore = alertStore;
    this.utilStore = utilStore;
    this.deviceStore = deviceStore;
  }

  setDevicesUnderControl = (devicesUnderControlList) => {
    this.devicesUnderControl = devicesUnderControlList;
  };

  setDevicesUnderTimeout = (devicesUnderTimeoutList) => {
    this.devicesUnderTimeout = devicesUnderTimeoutList;
  };

  setAgGridAction = (type, value) => {
    switch (type) {
      case 'connectedGridRefresh':
        this.agGridAction.connectedGrdiRefreshState = value;
        break;

      case 'unconnectedGridRefresh':
        this.agGridAction.unconnectedGrdiRefreshState = value;
        break;

      case 'connectedDeviceId':
        this.agGridAction.connectedDeviceId = value;
        break;

      case 'unconnectedDeviceId':
        this.agGridAction.unconnectedDeviceId = value;
        break;

      case 'connectedGridPageForce':
        this.agGridAction.connectedGridPageForce = value;
        break;

      case 'unconnectedGridPageForce':
        this.agGridAction.unconnectedGridPageForce = value;
        break;

      case 'selectedDeviceData':
        this.agGridAction.selectedDeviceData = value;
        break;
      default:
        console.error('type invalid');
        break;
    }
  };

  getControlKeyStatusList = (serial, isInit = false) => {
    return new Promise((resolve) => {
      this.deviceStore
        .getWebsocketEvnet(serial)
        .then((websocketHistoryData) => {
          let initDevicesUnderControl = [];
          let initDevicesUnderTimeout = [];
          /* Nested Function:  change 및 timeout key가 섞여 발생할때 장비 시리얼 기준으로 그룹핑 */
          const controlStatusGrouping = (websocketHistoryData) => {
            const initControlStatusList = [];
            websocketHistoryData.forEach((eachSocketData) => {
              const splitApplyData = eachSocketData?.key?.split(',');
              const selectedDeviceSerial = splitApplyData?.[1];
              const isSlave = splitApplyData?.[0]?.includes('slave.');
              const controlType = isSlave ? splitApplyData?.pop() : 'edit';
              const controlStatus = eachSocketData?.state;
              const controlValue = eachSocketData?.controlValue;
              const controlKey = splitApplyData?.[0]?.split('.')?.[1]
                ? isSlave
                  ? splitApplyData?.[0]?.split('.')?.[1]
                  : `${splitApplyData?.[0]?.split('.')?.[1]}.${
                      splitApplyData?.[1]
                    }`
                : eachSocketData?.key;

              if (
                !initControlStatusList.find((groupingData) =>
                  isSlave
                    ? groupingData?.deviceSerial === selectedDeviceSerial
                    : groupingData?.deviceSerial === serial,
                )
              ) {
                initControlStatusList.push({
                  deviceSerial: isSlave ? selectedDeviceSerial : serial,
                  controlType: [controlType],
                  controlKey: [controlKey],
                  controlStatus: [controlStatus],
                  controlValue: [controlValue],
                });
              } else {
                const existGroupArray = initControlStatusList.find(
                  (existData) =>
                    isSlave
                      ? existData.deviceSerial === selectedDeviceSerial
                      : existData.deviceSerial === serial,
                );
                existGroupArray.controlType.push(controlType);
                existGroupArray.controlKey.push(controlKey);
                existGroupArray.controlStatus.push(controlStatus);
                existGroupArray.controlValue.push(controlValue);
              }
            });
            return initControlStatusList;
          };

          if (websocketHistoryData) {
            const controlStatusList =
              controlStatusGrouping(websocketHistoryData);
            controlStatusList.forEach(async (eachGroupingData) => {
              const deviceSerial = eachGroupingData?.deviceSerial;
              const controlType = eachGroupingData?.controlType;
              const controlStatus = eachGroupingData?.controlStatus;
              /* Nested Function: change, timeout 상태의 장비들 init 또는 push */
              const setLoadingOrTimeout = (eachStatusData) => {
                switch (eachStatusData) {
                  case CONTROL_STATUS.LOADING:
                    if (
                      !initDevicesUnderControl.find(
                        (eachDeviceData) =>
                          eachDeviceData?.deviceSerial === deviceSerial,
                      )
                    ) {
                      initDevicesUnderControl.push({
                        ...eachGroupingData,
                        id: this.deviceStore.devices.find(
                          (eachDeviceData) =>
                            eachDeviceData.serial === deviceSerial,
                        )?.id,
                      });
                    }
                    break;

                  case CONTROL_STATUS.TIMEOUT:
                    if (
                      !initDevicesUnderTimeout.find(
                        (eachDeviceData) =>
                          eachDeviceData?.deviceSerial === deviceSerial,
                      )
                    ) {
                      initDevicesUnderTimeout.push({
                        ...eachGroupingData,
                        id: this.deviceStore.devices.find(
                          (eachDeviceData) =>
                            eachDeviceData.serial === deviceSerial,
                        )?.id,
                      });
                    }
                    break;

                  default:
                    break;
                }
              };

              const updateSlaveStatus = (selectedDeviceInfo, changeValue) => {
                this.deviceStore
                  .patchDeviceInfo({
                    id: selectedDeviceInfo.id,
                    key: 'routerSerial',
                    value: changeValue,
                  })
                  .then(() => {
                    this.deviceStore.getRouterSlaveDevices(serial);
                    this.deviceStore.getUnconnectedDevices();
                  });
              };

              if (deviceSerial === serial) {
                controlStatus?.forEach((eachStatusData) => {
                  setLoadingOrTimeout(eachStatusData);
                });
              } // Slave 제어 처리 로직
              else {
                const selectedDeviceInfo = this.deviceStore.devices?.find(
                  (deviceData) => deviceData.serial === deviceSerial,
                );

                controlStatus?.forEach((eachStatusData, eachStatusIndex) => {
                  switch (controlType[eachStatusIndex]) {
                    case 'add':
                      switch (eachStatusData) {
                        case CONTROL_STATUS.LOADING:
                          if (!isInit) {
                            updateSlaveStatus(selectedDeviceInfo, serial);
                          }
                          setLoadingOrTimeout(eachStatusData);
                          break;

                        case CONTROL_STATUS.TIMEOUT:
                          if (!isInit) {
                            updateSlaveStatus(selectedDeviceInfo, 'NULL');
                          }
                          setLoadingOrTimeout(eachStatusData);
                          break;

                        default:
                          break;
                      }
                      break;

                    case 'edit':
                      setLoadingOrTimeout(eachStatusData);
                      break;

                    case 'delete':
                      switch (eachStatusData) {
                        case CONTROL_STATUS.LOADING:
                          if (!isInit) {
                            updateSlaveStatus(selectedDeviceInfo, 'NULL');
                          }
                          setLoadingOrTimeout(eachStatusData);
                          break;

                        case CONTROL_STATUS.TIMEOUT:
                          if (!isInit) {
                            updateSlaveStatus(selectedDeviceInfo, serial);
                          }
                          setLoadingOrTimeout(eachStatusData);
                          break;

                        default:
                          break;
                      }
                      break;

                    default:
                      break;
                  }
                });
              }
            });
            this.setDevicesUnderControl(initDevicesUnderControl);
            this.setDevicesUnderTimeout(initDevicesUnderTimeout);
            this.setAgGridAction('unconnectedGridRefresh', true);
            this.setAgGridAction('connectedGridRefresh', true);
            resolve(true);
          }
        });
    });
  };

  // 라우터 통신 설정에 대한 웹소켓 응답 처리
  applyCommSettingChanges = (serial, socketData, controlStatus) => {
    switch (controlStatus) {
      case CONTROL_STATUS.LOADING:
        this.getControlKeyStatusList(serial).then(() => {
          this.setAgGridAction('unconnectedGridPageForce', null);
          this.setAgGridAction('connectedGridPageForce', null);
        });
        break;

      case CONTROL_STATUS.TIMEOUT:
        this.getControlKeyStatusList(serial).then(() => {
          this.setAgGridAction('selectedDeviceData', null);
          this.setAgGridAction('unconnectedGridPageForce', null);
          this.setAgGridAction('connectedGridPageForce', null);
          this.alertStore.setAlertOpen(
            'error',
            '장비 제어 명령이 실패했습니다.',
          );
        });
        break;

      case CONTROL_STATUS.IDLE:
        this.getControlKeyStatusList(serial).then(() => {
          let controlStatusList = [];
          socketData?.forEach((eachSocketData) => {
            const splitApplyData = eachSocketData?.key?.split(',');
            const isSlave = splitApplyData?.[0]?.includes('slave.');
            const controlKey = splitApplyData?.[0]?.split('.')?.[1];
            const controlType = isSlave ? splitApplyData?.pop() : 'edit';
            const selectedDeviceSerial =
              splitApplyData?.[1] || eachSocketData?.key;

            if (
              !controlStatusList.find((groupingData) =>
                isSlave
                  ? groupingData?.deviceSerial === selectedDeviceSerial
                  : groupingData?.deviceSerial === serial,
              )
            ) {
              controlStatusList.push({
                deviceSerial: isSlave ? selectedDeviceSerial : serial,
                controlType,
                controlKey: [controlKey],
              });
            } else {
              const existGroupArray = controlStatusList.find((existData) =>
                isSlave
                  ? existData.deviceSerial === selectedDeviceSerial
                  : existData.deviceSerial === serial,
              );
              existGroupArray.controlKey.push(controlKey);
            }
          });
          Promise.all(controlStatusList).then((totalGropuingData) => {
            totalGropuingData.forEach(async (eachGroupingData) => {
              const deviceSerial = eachGroupingData?.deviceSerial;
              const controlType = eachGroupingData?.controlType;
              if (
                !this.devicesUnderControl?.find(
                  (eachUnderControlDevice) =>
                    eachUnderControlDevice?.deviceSerial === deviceSerial,
                )
              ) {
                const selectedDeviceInfo = this.deviceStore.devices?.find(
                  (deviceData) => deviceData.serial === deviceSerial,
                );
                switch (controlType) {
                  case 'add':
                  case 'edit':
                    this.setAgGridAction(
                      'connectedDeviceId',
                      selectedDeviceInfo?.id,
                    );
                    break;
                  case 'delete':
                    this.setAgGridAction(
                      'unconnectedDeviceId',
                      selectedDeviceInfo.id,
                    );
                    break;
                  default:
                    break;
                }

                this.alertStore.setAlertOpen(
                  'success',
                  '장비 제어 명령에 성공했습니다.',
                );
              }
            });
          });
          this.setAgGridAction('selectedDeviceData', null);
          this.setAgGridAction('unconnectedGridPageForce', null);
          this.setAgGridAction('connectedGridPageForce', null);
        });
        break;

      default:
        console.error('Invalid control status');
        break;
    }
  };
}

export default EdmuStore;
