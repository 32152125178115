import { Navigate } from 'react-router-dom';
import { Loader, retryLazy } from 'src/utils/lazyUtil';

const ManufacturerList = Loader(
  retryLazy(() => import('src/pages/manufacturing/ManufacturerList')),
);

const ProductList = Loader(
  retryLazy(() => import('src/pages/manufacturing/ProductList')),
);

const monitoringRoutes = [
  {
    path: '',
    element: <Navigate to="manufacturer" replace />,
  },
  {
    path: 'manufacturer',
    element: <ManufacturerList />,
  },
  {
    path: 'product-list',
    element: <ProductList />,
  },
];

export default monitoringRoutes;
