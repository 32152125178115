import React, { useRef, useEffect } from 'react';
// import { InfoHero, InfoFeaturesTiles } from './style';
import { useTheme } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AppRoute from './utils/AppRoute';
import ScrollReveal from './utils/ScrollReveal';
import LayoutDefault from './layouts/LayoutDefault';
import CoverImagePage from './components/sections/CoverImagePage';
import FeaturesIconPage from './components/sections/FeaturesIconPage';
import FeaturesImagePage from './components/sections/FeaturesImagePage';
import AdvantagesPage from './components/sections/AdvantagesPage';
import ServicesPage from './components/sections/ServicesPage';

const information = () => {
  const theme = useTheme();
  const childRef = useRef();
  const location = useLocation();
  const { t } = useTranslation();

  useEffect(() => {
    document.body.classList.add('has-animations');
    document.body.classList.add('is-loaded');
    childRef.current.init();
  }, [location]);

  return (
    <>
      <ScrollReveal
        ref={childRef}
        // eslint-disable-next-line react/no-children-prop
        children={() => (
          <AppRoute
            exact
            path="/*"
            component={
              <>
                <Helmet>
                  <title>{`${t('소개')} - Okomo`}</title>
                </Helmet>
                {/* <InfoHero
                  className={`illustration-section-${theme.palette.primary.main.replace(
                    '#',
                    '',
                  )}`}
                  hasBgColor
                /> */}
                {/* <InfoFeaturesTiles /> */}
                <CoverImagePage
                  className={`illustration-section-${theme.palette.primary.main.replace(
                    '#',
                    '',
                  )}`}
                  hasBgColor
                />
                <FeaturesIconPage />
                <FeaturesImagePage />
                <AdvantagesPage />
                <ServicesPage />
              </>
            }
            layout={LayoutDefault}
          />
        )}
      />
    </>
  );
};

export default information;
