import { makeAutoObservable } from 'mobx';
import { getReverseGeocodingData } from 'src/api/location';

class locationStore {
  alertStore;

  constructor({ alertStore }) {
    makeAutoObservable(this);
    this.alertStore = alertStore;
  }

  getReverseGeocodingDataByCoordinates = async (
    lat,
    lon,
    language,
    resultType,
  ) => {
    try {
      const res = await getReverseGeocodingData(lat, lon, language, resultType);
      return res;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      throw error;
    }
  };
}

export default locationStore;
