import { useStore } from 'src/mobx-store';
import { useTheme, useMediaQuery } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { ToggleButton, SidebarFooterBox } from '../../style';

function SidebarFooter() {
  const { utilStore } = useStore();
  const { sidebarOpen, setSidebarOpen } = utilStore;
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  const renderIcon = () => {
    return mobile ? (
      <ChevronLeftIcon />
    ) : sidebarOpen ? (
      <ChevronLeftIcon />
    ) : (
      <KeyboardDoubleArrowRightIcon />
    );
  };

  return (
    <SidebarFooterBox sidebarOpen={sidebarOpen} mobileState={mobile}>
      <ToggleButton
        onClick={() => setSidebarOpen(!sidebarOpen)}
        sidebarOpen={sidebarOpen}
      >
        {renderIcon()}
      </ToggleButton>
    </SidebarFooterBox>
  );
}

export default SidebarFooter;
