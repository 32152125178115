import {
  IconButton,
  tooltipClasses,
  styled,
  alpha,
  Box,
  Badge,
  lighten,
  darken,
  Tooltip,
  ListItem,
  Button,
  Grid,
} from '@mui/material';

export const SidebarLayoutWrapper = styled(Box)(
  ({ theme }) => `
    flex: 1;
    height: 100%;
    display: block;
    @media (min-width: ${theme.breakpoints.values.sm}px) {
      display: flex;
    }
    .MuiPageTitle-wrapper {
        background: ${
          theme.palette.mode === 'dark'
            ? theme.colors.alpha.trueWhite[5]
            : theme.colors.alpha.white[50]
        };
        margin-bottom: ${theme.spacing(4)};
        box-shadow: ${
          theme.palette.mode === 'dark'
            ? `0 1px 0 ${alpha(
                lighten(theme.colors.primary.main, 0.7),
                0.15,
              )}, 0px 2px 4px -3px rgba(0, 0, 0, 0.2), 0px 5px 12px -4px rgba(0, 0, 0, .1)`
            : `0px 2px 4px -3px ${alpha(
                theme.colors.alpha.black[100],
                0.1,
              )}, 0px 5px 12px -4px ${alpha(
                theme.colors.alpha.black[100],
                0.05,
              )}`
        };
      }
    `,
);

export const ContentWrapper = styled(Box)(
  ({ theme }) => `
    position: relative;
    z-index: 0;
    display: block;
    flex: 1;
    padding-top: 60px;
    transition: margin-left 0.3s ease;

    @media (min-width: ${theme.breakpoints.values.lg}px) {
      margin-left: ${theme.sidebar.width};
    }

    @media (max-width: ${theme.breakpoints.values.lg - 1}px) {
      margin-left: 0px;
    }
      `,
);

export const SidebarWrapper = styled(Box)(
  ({ theme }) => `
    transition: all 0.3s ease;
    width: ${theme.sidebar.width};
    min-width: ${theme.sidebar.width};
    color: ${theme.colors.alpha.trueWhite[70]};
    position: relative;
    z-index: 7;
    height: 100%;
    padding-bottom: 61px;    
    background:
    ${
      theme.palette.mode === 'dark'
        ? theme.colors.alpha.white[100]
        : darken(theme.colors.alpha.black[100], 0.5)
    };
    .menu_divider {
      margin-top: ${theme.spacing(2)};
      margin-bottom: ${theme.spacing(2)};
      margin-left: ${theme.spacing(2)};
      margin-right: ${theme.spacing(2)};

    }

    .divider {
      background: ${theme.colors.alpha.trueWhite[10]};
    }
  `,
);

export const LogoWrapper = styled(Box)(
  ({ theme, sidebarOpen }) => `
      padding-left: 40px;
      display: flex;
      position: relative !important;
      flex-direction: column;
      margin-top : 9px; 
      margin-bottom : 18px; 
      
      .logo_box {
        margin-left: 21px;
        height: 35px;
      }
      
      .MuiChip-root {
        background: #5E90cc;
        position: absolute;
        top: ${sidebarOpen ? '0px' : '10px'};
        right: ${sidebarOpen ? '68px' : '21.5px'};
        height: 14px;
        width: 30px;
        color: ${theme.palette.primary.contrastText};
        font-size: 10px;
        text-align: center;
        font-weight: bold;
        cursor: pointer;
        span {
          padding: 2px;
        }
      }
  `,
);

export const MenuWrapper = styled(Box)(
  ({ theme }) => `
      .first_divider {
        visibility: hidden;
        margin-top: -${theme.spacing(3)};
      }
      .sidebar_divider {
        margin-left: ${theme.spacing(1)};
        margin-right: ${theme.spacing(1)};
        margin-bottom: ${theme.spacing(1)};
        background: ${theme.colors.alpha.trueWhite[10]};
      }
      .MuiList-root {
        padding: ${theme.spacing(1)};
    
        & > .MuiList-root {
          padding: 0 ${theme.spacing(0)} ${theme.spacing(1)};
        }
      }
    
        .MuiListSubheader-root {
          text-transform: uppercase;
          font-weight: bold;
          font-size: ${theme.typography.pxToRem(12)};
          color: ${theme.colors.alpha.trueWhite[50]};
          padding: ${theme.spacing(0, 2.5)};
          line-height: 1.4;
        }
    `,
);

export const SubMenuWrapper = styled(Box)(
  ({ theme }) => `
        .MuiList-root {
    
          .MuiListItem-root {
            padding: 1px 0;
    
            .MuiBadge-root {
              position: absolute;
              right: ${theme.spacing(3.2)};
    
              .MuiBadge-standard {
                background: ${theme.colors.primary.main};ß
                font-size: ${theme.typography.pxToRem(10)};
                font-weight: bold;
                text-transform: uppercase;
                color: ${theme.palette.primary.contrastText};
              }
            }
        
            
            .MuiButton-root {
              display: flex;
              color: ${theme.colors.alpha.trueWhite[70]};
              background-color: transparent;
              width: 100%;
              justify-content: flex-start;
              padding: ${theme.spacing(1.2, 3)};
              
  
              &:before {
                content: ' ';
                background: ${theme.colors.alpha.trueWhite[100]};
                opacity: 0;
                transition: ${theme.transitions.create([
                  'transform',
                  'opacity',
                ])};
                width: 6px;
                height: 6px;
                transform: scale(0);
                transform-origin: center;
                border-radius: 20px;
                margin-right: ${theme.spacing(1.8)};
              }
  
  
              .MuiButton-startIcon,
              .MuiButton-endIcon {
                transition: ${theme.transitions.create(['color'])};
    
                .MuiSvgIcon-root {
                  font-size: inherit;
                  transition: none;
                }
              }
    
              .MuiButton-startIcon {
                color: ${theme.colors.alpha.trueWhite[30]};
                font-size: ${theme.typography.pxToRem(20)};
                margin-right: ${theme.spacing(1)};
              }
              
              .MuiButton-endIcon {
                color: ${theme.colors.alpha.trueWhite[50]};                
                margin-left: auto;
                opacity: .8;
                font-size: ${theme.typography.pxToRem(20)};
              }
    
              &.active,
              &:hover {
                background-color: ${alpha(
                  theme.colors.alpha.trueWhite[100],
                  0.06,
                )};
                color: ${theme.colors.alpha.trueWhite[100]};
    
                .MuiButton-startIcon,
                .MuiButton-endIcon {
                  color: ${theme.colors.alpha.trueWhite[100]};
                }
              }
            }
    
            &.Mui-children {
              flex-direction: column;
    
              .MuiBadge-root {
                position: absolute;
                right: ${theme.spacing(7)};
              }
            }
    
  
            
            .MuiCollapse-root {
              width: 100%;
    
              .MuiList-root {
                padding: ${theme.spacing(1, 0)};
              }
    
              .MuiListItem-root {
                padding: 1px 0;
    
                .MuiButton-root {
                  padding: ${theme.spacing(0.8, 3)};
    
                  .MuiBadge-root {
                    right: ${theme.spacing(3.2)};
                  }
    
                  &:before {
                    content: ' ';
                    background: ${theme.colors.alpha.trueWhite[100]};
                    opacity: 0;
                    transition: ${theme.transitions.create([
                      'transform',
                      'opacity',
                    ])};
                    width: 6px;
                    height: 6px;
                    transform: scale(0);
                    transform-origin: center;
                    border-radius: 20px;
                    margin-right: ${theme.spacing(1.8)};
                  }
    
                  &.active,
                  &:hover {
    
                    &:before {
                      transform: scale(1);
                      opacity: 1;
                    }
                  }
                }
              }
            }
          }
        }
    `,
);

export const OuterSubMenuWrapper = styled(Box)(
  ({ theme, sidebarOpen }) => `
    .MuiList-root {

      span {
        white-space: pre-wrap;
      }

      .MuiListItem-root {
        padding: 1px 0;

        .MuiBadge-root {
          position: absolute;
          right: ${theme.spacing(3.2)};

          .MuiBadge-standard {
            background: ${theme.colors.primary.main};
            font-size: ${theme.typography.pxToRem(10)};
            font-weight: bold;
            text-transform: uppercase;
            color: ${theme.palette.primary.contrastText};
          }
        }
    
        .MuiButton-root {
          display: flex;
          color: ${theme.colors.alpha.trueWhite[70]};
          background-color: transparent;
          width: 100%;
          justify-content: flex-start;
          padding: ${theme.spacing(1.2, 3)};

          .MuiButton-startIcon,
          .MuiButton-endIcon {
            transition: ${theme.transitions.create(['color'])};

            .MuiSvgIcon-root {
              font-size: inherit;
              transition: none;
            }
          }

          

          .MuiButton-startIcon {
            color: ${theme.colors.alpha.trueWhite[30]};
            font-size: ${theme.typography.pxToRem(20)};
            margin-right: ${theme.spacing(1)};
          }
          

          .MuiButton-endIcon {
            color: ${theme.colors.alpha.trueWhite[50]};                
            margin-left: ${sidebarOpen ? 'auto' : '-0.6rem'};
            opacity: .8;
            font-size: ${theme.typography.pxToRem(20)};
          }

          &.active,
          &:hover {
            background-color: ${alpha(theme.colors.alpha.trueWhite[100], 0.06)};
            color: ${theme.colors.alpha.trueWhite[100]};

            .MuiButton-startIcon,
            .MuiButton-endIcon {
              color: ${theme.colors.alpha.trueWhite[100]};
            }
          }
        }

        &.Mui-children {
          flex-direction: column;

          .MuiBadge-root {
            position: absolute;
            right: ${theme.spacing(7)};
          }
        }

        .MuiCollapse-root {
          width: 100%;
          padding-left : 15px;

          .MuiList-root {
            padding: ${theme.spacing(1, 0)};
          }

          .MuiListItem-root {
            padding: 1px 0;
            
            .MuiButton-root {
              padding: ${theme.spacing(0.8, 3)};
            
              .MuiBadge-root {
                right: ${theme.spacing(3.2)};
              }

              &.active,
              &:hover {

                &:before {
                  transform: scale(1);
                  opacity: 1;
                }
              }
            }
          }
        }
      }
    }
    `,
);

export const SubMenuDeviceWrapper = styled(Box)(
  ({ theme, sidebarOpen }) => `
    .MuiList-root {
      .MuiListItem-root {
        padding: 1px 0;

        .MuiBadge-root {
          position: absolute;
          right: ${theme.spacing(3.2)};

          .MuiBadge-standard {
            background: ${theme.colors.primary.main};
            font-size: ${theme.typography.pxToRem(10)};
            font-weight: bold;
            text-transform: uppercase;
            color: ${theme.palette.primary.contrastText};
          }
        }
    
        .MuiButton-root {
          display: flex;
          color: ${theme.colors.alpha.trueWhite[70]};              
          background-color: transparent;
          width: 100%;
          justify-content: flex-start;
          padding: ${theme.spacing(1.2, 3)};

          .MuiButton-startIcon,
          .MuiButton-endIcon {
            transition: ${theme.transitions.create(['color'])};

            .MuiSvgIcon-root {
              font-size: inherit;
              transition: none;
            }
          }

          .MuiButton-startIcon {
            color: ${
              sidebarOpen
                ? theme.colors.alpha.trueWhite[50]
                : theme.header.textColor
            };
            font-size: ${theme.typography.pxToRem(20)};
            margin-right: ${theme.spacing(1)};
          }
          
          .MuiButton-endIcon {
            color: ${
              sidebarOpen
                ? theme.colors.alpha.trueWhite[50]
                : theme.header.textColor
            };
            margin-left: auto;
            opacity: .8;
            font-size: ${theme.typography.pxToRem(20)};
          }

          &.active,
          &:hover {
            background-color: ${alpha(
              sidebarOpen
                ? theme.colors.alpha.trueWhite[100]
                : theme.header.textColor,
              0.06,
            )};
            color: ${theme.colors.alpha.trueWhite[100]};

            .MuiButton-startIcon,
            .MuiButton-endIcon {
              color: ${
                sidebarOpen
                  ? theme.colors.alpha.trueWhite[100]
                  : theme.header.textColor
              };
            }
          }
        }

        &.Mui-children {
          flex-direction: column;

          .MuiBadge-root {
            position: absolute;
            right: ${theme.spacing(7)};
          }
        }

        .MuiCollapse-root {
          width: 100%;

          .MuiList-root {
            padding: ${theme.spacing(1, 0)};
          }

          .MuiListItem-root {
            padding: 1px 0;
            margin-left: ${sidebarOpen ? '0' : '16px'};
            .MuiButton-root {
              padding: ${theme.spacing(0.8, 3.2)};

              .MuiBadge-root {
                right: ${theme.spacing(3.2)};
              }

              &:before {
                content: ' ';
                background: ${
                  sidebarOpen
                    ? theme.colors.alpha.trueWhite[100]
                    : theme.header.textColor
                };
                opacity: 0;
                transition: ${theme.transitions.create([
                  'transform',
                  'opacity',
                ])};
                width: 6px;
                height: 6px;
                transform: scale(0);
                transform-origin: center;
                border-radius: 20px;
                margin-right: ${theme.spacing(2.2)};
              }

              &.active,
              &:hover {
                background-color: ${alpha(
                  sidebarOpen
                    ? theme.colors.alpha.trueWhite[100]
                    : theme.header.textColor,
                  0.06,
                )};
                &:before {
                  transform: scale(1);
                  opacity: 1;
                }
              }
            }
          }
        }
      }
    }
    `,
);

export const TooltipWrapper = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.colors.alpha.trueWhite[100],
    color: theme.palette.getContrastText(theme.colors.alpha.trueWhite[100]),
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 'bold',
    borderRadius: theme.general.borderRadiusSm,
    boxShadow:
      '0 .2rem .8rem rgba(7,9,25,.18), 0 .08rem .15rem rgba(7,9,25,.15)',
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.colors.alpha.trueWhite[100],
  },
}));

export const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme, placement }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.colors.alpha.trueWhite[100],
    color: theme.palette.getContrastText(theme.colors.alpha.trueWhite[100]),
    boxShadow: theme.shadows[24],
    fontSize: theme.typography.pxToRem(12),
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.colors.alpha.trueWhite[100],
  },
  '& .icon_box': {
    background: theme.colors.alpha.trueWhite[10],
    color: theme.colors.alpha.trueWhite[70],
  },
  '& .dropdown_menu_label': {
    padding: '9px 16px',
  },
  '& .MuiTooltip-tooltip': {
    maxWidth: '20rem',
    color: theme.header.textColor,
    fontWeight: 'bold',
    fontSize: theme.typography.pxToRem(14),
    background: theme.palette.background.paper,
    margin:
      placement === 'bottom'
        ? '-3.25rem -22rem 0rem 2rem !important'
        : '-0.25rem 0rem 0rem 0.95rem !important',
    display: 'block',
    zIndex: 0,
    boxShadow:
      theme.palette.mode === 'dark'
        ? '0.1px 0.1px 4.5px 0px'
        : '0.5px 0.5px 7px 1.5px',
    padding: '0px',
  },
  '& .MuiTooltip-arrow': {
    color: theme.palette.background.paper,
    transform: placement === 'bottom' && 'rotate( -90deg ) !important',
    margin: placement === 'bottom' && '1.5rem 0rem 0rem -0.6rem !important',
  },
  '.tooltip_title_div': {
    maxHeight: '500px',
    overflow: 'auto',
  },
}));

export const TooltipContentButton = styled(Button)(
  ({ theme }) => `
    color: ${theme.header.textColor};
    &:hover {
      background-color: ${alpha(theme.header.textColor, 0.06)};
    }
  `,
);

export const ToggleButton = styled(IconButton)(
  ({ theme, sidebarOpen }) => `        
    background: ${theme.colors.alpha.trueWhite[10]};
    color:${theme.colors.alpha.trueWhite[70]};
    transition: ${theme.transitions.create(['all'])};
    
    :hover {
      background: ${alpha(theme.colors.alpha.trueWhite[100], 0.2)};
      color: ${theme.colors.alpha.trueWhite[100]};
    },
    margin-left: 0px;
    margin-right: ${sidebarOpen ? '18px' : '0px'};
    `,
);

export const BadgeAnimation = styled(Badge)(
  ({ theme }) => `
    .MuiBadge-badge {
      animation: pulse 1s infinite;
      top: 5%;
      transition: ${theme.transitions.create(['all'])};
    }
    `,
);

export const SidebarFooterBox = styled(Box)(
  ({ sidebarOpen, mobileState }) => `
    display: flex;
    align-items: center;
    height: 60px;
    justify-content: ${mobileState || !sidebarOpen ? 'center' : 'flex-end'};
    `,
);

export const SidebarProfileBox = styled(Box)(
  ({ theme }) => `
    display : flex;
    padding: ${theme.spacing(2)};
    justify-content: center;
    align-items: center;
    .avater_box {
      background: ${alpha('#ffffff', 0.3)};
    }
    `,
);

export const ProfileTextBox = styled(Box)(
  ({ theme, sidebarOpen }) => `
    text-align: left;
    padding: ${theme.spacing(1.5)};
    
    .profile_name{      
      color: ${
        sidebarOpen ? theme.colors.alpha.trueWhite[100] : theme.header.textColor
      };
      font-weight: ${!sidebarOpen && 'bold'};
    }
  
    .profile_subname{
      color: ${
        sidebarOpen ? theme.colors.alpha.trueWhite[70] : theme.header.textColor
      };
    }
  `,
);

export const HeaderWrapper = styled(Box)(
  ({ theme }) => `
    height: 60px;
    color: ${theme.header.textColor};
    padding: ${theme.spacing(0, 2)};
    right: 0;
    z-index: 6;
    background-color: ${alpha(theme.header.background, 0.95)};
    backdrop-filter: blur(3px);
    position: fixed;
    width: 100%;
    box-shadow: ${
      theme.palette.mode === 'dark'
        ? `0 1px 0 ${alpha(
            lighten(theme.colors.primary.main, 0.7),
            0.15,
          )}, 0px 2px 8px -3px rgba(0, 0, 0, 0.2), 0px 5px 22px -4px rgba(0, 0, 0, .1)`
        : `0px 2px 8px -3px ${alpha(
            theme.colors.alpha.black[100],
            0.2,
          )}, 0px 5px 22px -4px ${alpha(theme.colors.alpha.black[100], 0.1)}`
    };
    .header_buttons_grid {
      justify-content: space-between;
      @media (max-width: 900px) {
        justify-content: flex-end;
      } 
    }
    
  `,
);

export const MobileMenuBox = styled(Box)(
  () => `
    display: none;
  
    @media (max-width: 1279px) {
      display: inline-block;
    }
  
    animation: menuButtonScaleUp 0.5s forwards;
    transform: scale(0);
  
    @keyframes menuButtonScaleUp {
      0% {
        transform: scale(0);
      }
      100% {
        transform: scale(1);
      }
    }  
  `,
);

export const WelcomeMsg = styled(Grid)(
  ({ sidebarOpen }) => `
    transform: translate(${sidebarOpen ? '18rem' : '4.5rem'}, 0);
    transition: transform 0.2s linear;
    @media (max-width : 900px)  {
      display: none;
    }
  `,
);

export const MenuListItem = styled(ListItem)(
  ({ theme, sidebarOpen, parentState }) => `
    justify-content: ${sidebarOpen && parentState ? 'initial' : 'center'};
    .sidebar_dropdown_menu {
      color: ${theme.header.textColor};
      background-color: ${alpha(theme.colors.alpha.trueWhite[100], 0.06)};
    }
  `,
);
