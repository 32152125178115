import { styled, Box } from '@mui/material';
import lightLogo from 'src/assets/images/okomoForDark.png';
import darkLogo from 'src/assets/images/okomoForLight.png';

export const LogoImage = styled(Box)(
  ({ theme, alwaysuselightlogo }) => `
        color: ${theme.palette.text.primary};
        display: flex;
        background-image: url(${
          alwaysuselightlogo
            ? lightLogo
            : theme.palette.mode === 'dark'
            ? lightLogo
            : darkLogo
        });
        width: 115%;
        height: 115%;
        background-size: contain;
        background-repeat: no-repeat;
        margin: auto;
        justify-content: center;
`,
);
