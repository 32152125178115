import { styled } from '@mui/material';

export const LocationMapDiv = styled('div')(
  ({ theme }) => `
  position: static;
  height: 32rem;
  width: 100%;

  .pac-container {
    z-index: 1500!;
  }

  .search_box {
    position: static;
    border: 1px dashed grey;
    width: 17.5rem;
    z-index: 1;
    background: ${theme.palette.background.paper};
    margin-top: 1rem;
    border-radius: 0.5rem;
  }

  .search_input {
    margin-left: -0.2rem;
    width: 120%;
    .MuiInputBase-input
    {
      font-size : 0.85rem;
      @media (max-width: 599px) {
        font-size : 0.7rem;
     }
    }
  }

  .search_result {
    padding: 0.5rem 0.5rem 0rem 0.5rem;
    font-size : 0.85rem;
    @media (max-width: 599px) {
      font-size : 0.7rem;
   }
  }

  .current_location_button {
    color: ${theme.palette.primary.main};
    margin-top: 0.25rem;
  }

  .confirm_buttom {
    color: ${theme.palette.primary.main};
  }
  `,
);
