import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Logo from './partials/Logo';
import { InfoA, InfoLanguageSwitcherWrapper, LogoWrapper } from './style';
import LanguageSwitcher from 'src/components/LanguageSwitcher';
import { useTheme, Chip, Box } from '@mui/material';
// import ThemeSettings from 'src/components/ThemeSettings';

const propTypes = {
  navPosition: PropTypes.string,
  hideNav: PropTypes.bool,
  hideSignin: PropTypes.bool,
  bottomOuterDivider: PropTypes.bool,
  bottomDivider: PropTypes.bool,
};

const defaultProps = {
  navPosition: '',
  hideNav: false,
  hideSignin: false,
  bottomOuterDivider: false,
  bottomDivider: false,
};

const Header = ({
  className,
  navPosition,
  hideNav,
  hideSignin,
  bottomOuterDivider,
  bottomDivider,
  ...props
}) => {
  const [isActive, setIsactive] = useState(false);
  const theme = useTheme();
  const nav = useRef(null);
  const hamburger = useRef(null);

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    isActive && openMenu();
    document.addEventListener('keydown', keyPress);
    document.addEventListener('click', clickOutside);
    return () => {
      document.removeEventListener('keydown', keyPress);
      document.removeEventListener('click', clickOutside);
      closeMenu();
    };
  });

  const openMenu = () => {
    document.body.classList.add('off-nav-is-active');
    nav.current.style.maxHeight = nav.current.scrollHeight + 'px';
    setIsactive(true);
  };

  const closeMenu = () => {
    document.body.classList.remove('off-nav-is-active');
    // eslint-disable-next-line no-unused-expressions
    nav.current && (nav.current.style.maxHeight = null);
    setIsactive(false);
  };

  const keyPress = (e) => {
    // eslint-disable-next-line no-unused-expressions
    isActive && e.keyCode === 27 && closeMenu();
  };

  const clickOutside = (e) => {
    if (!nav.current) return;
    if (
      !isActive ||
      nav.current.contains(e.target) ||
      e.target === hamburger.current
    )
      return;
    closeMenu();
  };

  const classes = classNames(
    'site-header',
    bottomOuterDivider && 'has-bottom-divider',
    className,
  );
  return (
    <header {...props} className={classes}>
      <div className="container">
        <div
          className={classNames(
            'site-header-inner',
            bottomDivider && 'has-bottom-divider',
          )}
        >
          <LogoWrapper>
            <Chip label={`V.${process.env.REACT_APP_VERSION}`} />
            <Box className="logo_box">
              <Logo linkUrl={'information'} />
            </Box>
          </LogoWrapper>
          {!hideNav && (
            <>
              <button
                ref={hamburger}
                type="button"
                className="header-nav-toggle"
                onClick={isActive ? closeMenu : openMenu}
              >
                <span className="screen-reader">Menu</span>
                <span className="hamburger">
                  <span className="hamburger-inner" />
                </span>
              </button>
              <nav
                ref={nav}
                className={classNames(
                  'header-nav',
                  isActive && 'is-active',
                  theme.palette.mode,
                )}
              >
                <div className="header-nav-inner">
                  {!hideSignin && (
                    <ul className="list-reset header-nav-right">
                      <li>
                        <InfoA
                          href="/account/login"
                          className="button button-primary button-wide-mobile button-sm"
                          onClick={closeMenu}
                        >
                          Sign in
                        </InfoA>
                      </li>
                      <li>
                        <InfoA
                          href="/account/register"
                          className="button button-primary button-wide-mobile button-sm"
                          onClick={closeMenu}
                        >
                          Sign up
                        </InfoA>
                      </li>
                    </ul>
                  )}
                </div>
              </nav>
            </>
          )}
          <InfoLanguageSwitcherWrapper>
            <LanguageSwitcher />
          </InfoLanguageSwitcherWrapper>
          {/* <ThemeSettings /> */}
        </div>
      </div>
    </header>
  );
};

Header.propTypes = propTypes;
Header.defaultProps = defaultProps;

export default Header;
