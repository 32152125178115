// import { Box, Typography, Button } from '@mui/material';
// import { useTranslation } from 'react-i18next';
import LanguageSwitcher from 'src/components/LanguageSwitcher';
import ThemeSettingsButton from 'src/components/ThemeSettingsButton';
import { HeaderButtonsBox } from '../style';
import Clock from '../../../Clock';

const HeaderButtons = () => {
  // const { t } = useTranslation();

  return (
    <HeaderButtonsBox>
      {/* <Box className="desktop trial_msg_box">
        <Typography>{t('업그레이드 메세지', { day: 14 })}</Typography>
        <Button className="upgrade_button" variant="contained">
          Upgrade
        </Button>
      </Box>
      <Button className="mobile upgrade_button" variant="contained">
        Upgrade
      </Button> */}
      <Clock />
      <ThemeSettingsButton />
      <LanguageSwitcher />
    </HeaderButtonsBox>
  );
};

export default HeaderButtons;
