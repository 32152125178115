import { styled, Box } from '@mui/material';

export const InfoA = styled(`a`)(
  ({ theme }) => `
    background: ${theme.colors.primary.main} !important;
    border-radius: 6px !important;
    font-weight: 500 !important;
        `,
);

export const InfoLanguageSwitcherWrapper = styled(Box)(
  () => `
  .MuiIconButton-root{
    float: right;
    margin: 5px;
  }
  padding: 5px;
  margin-top: 1px;
  margin-left: 10px;
`,
);

export const LogoWrapper = styled(Box)(
  ({ theme }) => `
    display: flex;
    position: relative !important;
    flex-direction: column;
    align-items: center;     
    width : 180px;
    margin-top: -7.5px;
    @media (max-width: 820px) {
      width : 150px;
      margin-top: 0px;
    }
    .logo_box {
      margin-right: -20px;
      @media (max-width: 820px) {
        margin-right: 0px;
      }
    }

    .MuiChip-root {
      background: #5E90cc;
      position: absolute;
      top: 0;
      right: 0;
      height: 14px;
      width: 30px;
      color: ${theme.palette.primary.contrastText};
      font-size: 10px;
      text-align: center;
      font-weight: bold;

      span {
        padding: 2px;
      }
    }
`,
);
