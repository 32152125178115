import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import Image from '../elements/Image';
import { useTheme } from '@mui/material';

const Logo = ({ className, ...props }) => {
  const theme = useTheme();
  const classes = classNames('brand', className);
  return (
    <div {...props} className={classes}>
      <h1 className="m-0">
        <Link to={props.linkUrl ? `/${props.linkUrl}` : '/'}>
          {theme.palette.mode === 'light' ? (
            <Image
              // eslint-disable-next-line global-require
              src={require('src/pages/information/assets/images/okomoForLight.png')}
              alt="Open"
              width={150}
            />
          ) : (
            <Image
              // eslint-disable-next-line global-require
              src={require('src/pages/information/assets/images/okomoForDark.png')}
              alt="Open"
              width={150}
            />
          )}
        </Link>
      </h1>
    </div>
  );
};

export default Logo;
