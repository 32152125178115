import { styled } from '@mui/material';
import Moment from 'react-moment';

export const MomentClock = styled(Moment)(
  () => `
    margin-top: 0.32rem;
    margin-right: 1.2rem;
    font-weight: 600;
    font-size: 1.05rem;
    @media (max-width : 599px){
      font-size: 0.675rem;
      margin-top: 0.62rem;   
      margin-right: 1rem;
    }
  `,
);
