import React from 'react';
import { Routes, Route } from 'react-router-dom';

const AppRoute = ({ component: Component, layout: Layout, ...rest }) => {
  const Layouts =
    Layout === undefined ? (props) => <>{props.children}</> : Layout;

  return (
    <Routes>
      <Route {...rest} element={<Layouts>{Component}</Layouts>} path="*" />
    </Routes>
  );
};

export default AppRoute;
