import { makeAutoObservable } from 'mobx';
import {
  getUserInfo,
  getAccessToken,
  createNewUser,
  verifyEmail,
  generateVerificationEmail,
  getUserEmail,
  updateUserPasswordByEmail,
  generateChangePasswordEmail,
  updatePasswordByPassword,
  updateUserInfo,
  deleteUser,
  verifyCuKey,
  deleteSubEmail,
} from 'src/api/account';
import { AUTH_STATUS } from 'src/assets/constants';

class AccountStore {
  alertStore;

  account = {
    code: '',
    username: '',
    email: '',
    subEmail: '',
    phone: -1,
    role: '',
    jobTitle: '',
    profileImageUrl: '',
    companyId: -1,
    isEmailVerified: false,
    subEmailState: 'n',
    countryCode: 'US',
    timeZone: 'America/New_York',
    dateFormat: {
      order: 'YYYYMMDD',
      delimiter: '-',
      string: 'YYYY-MM-DD',
    },
    location: {
      id: 18,
      name: '안산대',
      type: 'device',
      address: '경기 안산시 상록구 안산대학로 15 (이동)',
      addressDetail: '155',
      zipCode: '15518',
      lat: null,
      lon: null,
    },
  };

  constructor({ alertStore }) {
    makeAutoObservable(this);
    this.alertStore = alertStore;
  }

  initializeAccount = () => {
    this.account = {
      code: '',
      username: '',
      email: '',
      subEmail: '',
      phone: -1,
      role: '',
      jobTitle: '',
      profileImageUrl: '',
      companyId: -1,
      isEmailVerified: false,
      subEmailState: 'n',
      countryCode: 'US',
      timeZone: 'America/New_York',
      dateFormat: {
        order: 'YYYYMMDD',
        delimiter: '-',
        string: 'YYYY-MM-DD',
      },
      location: null,
    };
  };

  setProfile = (account) => {
    this.account = account;
  };

  getProfile = async () => {
    try {
      const res = await getUserInfo();
      this.setProfile(res);
      return true;
    } catch (error) {
      this.setProfile({
        username: '',
        email: '',
        phone: -1,
        role: '',
        profileImageUrl: '',
        companyId: -1,
        isEmailVerified: AUTH_STATUS.EXPIRED,
        countryCode: 'KR',
      });
      return [500, { message: 'Encountered a server error' }];
    }
  };

  // eslint-disable-next-line class-methods-use-this
  submitSignIn = async (email, password) => {
    try {
      return await getAccessToken(email, password);
    } catch (error) {
      if (error.code === 'ERR_NETWORK')
        this.alertStore.setAlertOpen(
          'error',
          'Okomo 서버에 연결할 수 없습니다.\n사용자 네트워크 환경 문제나 방화벽 등이 문제가 될 수 있습니다.\n확인 후 이 오류가 반복되면 관리자에게 문의하세요',
        );
      throw error;
    }
  };

  // eslint-disable-next-line class-methods-use-this
  submitSignUp = async (
    email,
    password,
    username,
    phone,
    language,
    countryCode,
    timeZone,
  ) => {
    try {
      const accessToken = await createNewUser(
        email,
        password,
        username,
        phone,
        language,
        countryCode,
        timeZone,
      );
      // 알림 모달창 출력
      this.alertStore.setAlertOpen('success', '회원가입이 정상처리 되었습니다');
      return accessToken;
    } catch (error) {
      if (error.code === 'ERR_NETWORK')
        this.alertStore.setAlertOpen(
          'error',
          'Okomo 서버에 연결할 수 없습니다.\n사용자 네트워크 환경 문제나 방화벽 등이 문제가 될 수 있습니다.\n확인 후 이 오류가 반복되면 관리자에게 문의하세요',
        );
      throw error;
    }
  };

  sendVerificationEmail = async (language, type, email) => {
    try {
      await generateVerificationEmail(language, type, email);
      // 알림 모달창 출력
      this.alertStore.setAlertOpen(
        'success',
        '정상적으로 인증 메일이 발송되었습니다.\n 인증 메일을 눌러 회원가입을 진행해 주세요.',
        { mode: 'modal' },
      );

      return true;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      throw error;
    }
  };

  // eslint-disable-next-line class-methods-use-this
  authenticateEmail = async (verificationCode, type) => {
    try {
      await verifyEmail(verificationCode, type);
      this.alertStore.setAlertOpen(
        'success',
        '이메일 인증이 정상적으로 처리되었습니다.',
        { mode: 'modal' },
      );
      return true;
    } catch (error) {
      console.error('Error: ', error);
      throw error;
    }
  };

  deleteSubEmailByPassword = async (password) => {
    try {
      await deleteSubEmail(password);
      this.alertStore.setAlertOpen(
        'success',
        '보조 이메일 삭제가 정상적으로 처리되었습니다.',
      );
      return true;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      throw error;
    }
  };

  sendPasswordChangeEmail = async (email, language) => {
    try {
      await generateChangePasswordEmail(email, language);
      // 알림 모달창 출력
      this.alertStore.setAlertOpen(
        'success',
        '비밀번호 초기화를 위한 인증 코드가 이메일로 발송되었습니다.\n받으신 인증 코드를 입력하여 비밀번호를 재설정해 주세요.',
        { mode: 'modal' },
      );

      return true;
    } catch (error) {
      if (error.code === 'ERR_NETWORK')
        this.alertStore.setAlertOpen(
          'error',
          'Okomo 서버에 연결할 수 없습니다.\n사용자 네트워크 환경 문제나 방화벽 등이 문제가 될 수 있습니다.\n확인 후 이 오류가 반복되면 관리자에게 문의하세요',
        );
      throw error;
    }
  };

  resetPassword = async (password, verificationCode, email) => {
    try {
      await updateUserPasswordByEmail(password, verificationCode, email);
      // 알림 모달창 출력
      this.alertStore.setAlertOpen(
        'success',
        '비밀번호가 정상적으로 수정되었습니다.',
        { linkTo: '/account/login', mode: 'modal' },
      );
      return true;
    } catch (error) {
      console.error('Error: ', error);
      throw error;
    }
  };

  // eslint-disable-next-line class-methods-use-this
  findEmail = async (username, phone, countryCode) => {
    try {
      return await getUserEmail(username, phone, countryCode);
    } catch (error) {
      if (error.code === 'ERR_NETWORK')
        this.alertStore.setAlertOpen(
          'error',
          'Okomo 서버에 연결할 수 없습니다.\n사용자 네트워크 환경 문제나 방화벽 등이 문제가 될 수 있습니다.\n확인 후 이 오류가 반복되면 관리자에게 문의하세요',
        );
      throw error;
    }
  };

  // eslint-disable-next-line class-methods-use-this
  changePassword = async (password, newPassword) => {
    try {
      await updatePasswordByPassword(password, newPassword);
      return true;
    } catch (error) {
      console.log('Error: ', error);
      throw error;
    }
  };

  // eslint-disable-next-line class-methods-use-this
  changeUserInfo = async (
    userName,
    phone,
    profileImage,
    countryCode,
    address,
    zipCode,
    addressDetail,
    lat,
    lon,
    jobTitle,
    timeZone,
    dateFormatOrder,
    dateFormatDelimiter,
  ) => {
    try {
      await updateUserInfo(
        userName,
        phone,
        profileImage,
        countryCode,
        address,
        zipCode,
        addressDetail,
        lat,
        lon,
        jobTitle,
        timeZone,
        dateFormatOrder,
        dateFormatDelimiter,
      );
      return true;
    } catch (error) {
      console.error('Error: ', error);
      throw error;
    }
  };

  changeUserProfiles = async (
    password,
    newPassword,
    userName,
    phone,
    profileImage,
    countryCode,
    address,
    zipCode,
    addressDetail,
    lat,
    lon,
    jobTitle,
    timeZone,
    dateFormatOrder,
    dateFormatDelimiter,
  ) => {
    try {
      if (profileImage === this.account.profileImageUrl) {
        profileImage = null;
      } else if (profileImage === undefined) {
        profileImage = -1;
      }
      await this.changeUserInfo(
        userName,
        phone,
        profileImage,
        countryCode,
        address,
        zipCode,
        addressDetail,
        lat,
        lon,
        jobTitle,
        timeZone,
        dateFormatOrder,
        dateFormatDelimiter,
      );
      if (password) {
        await this.changePassword(password, newPassword);
      }
      this.getProfile();
      this.alertStore.setAlertOpen(
        'success',
        '프로필 정보 수정이 정상적으로 처리되었습니다.',
      );
      return true;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      throw error;
    }
  };

  // eslint-disable-next-line class-methods-use-this
  withdrawalAccount = async (password) => {
    try {
      await deleteUser(password);

      this.alertStore.setAlertOpen(
        'success',
        '회원 탈퇴가 정상적으로 처리되었습니다.',
      );
      return true;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);

      throw error;
    }
  };

  submitCerificationCuKey = async (cuKey) => {
    try {
      const res = await verifyCuKey(cuKey);
      return res;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      throw error;
    }
  };
}

export default AccountStore;
