import axios from 'src/utils/axios';

export const postNewProduct = async (newManufacturerLocationInfo) => {
  try {
    const res = await axios.post(`/edmu/device/model`, {
      deviceTypeId: newManufacturerLocationInfo.deviceTypeId,
      name: newManufacturerLocationInfo.name,
      model: newManufacturerLocationInfo.model,
      manufacturingCompanyId: newManufacturerLocationInfo.manufacturerId,
      description: newManufacturerLocationInfo.description,
    });
    return res;
  } catch (error) {
    return error;
  }
};

export const getProductList = async () => {
  try {
    const res = await axios.get(`/edmu/device/model`);
    return res.data;
  } catch (error) {
    return error;
  }
};

export const patchProductInfor = async (modifyInfo) => {
  const res = await axios.patch(
    `edmu/device/model?id=${modifyInfo.id}`,
    modifyInfo.data,
  );
  return res;
};

export const deleteProduct = async (drawingId) => {
  const res = await axios.delete(`edmu/device/model?id=${drawingId}`);
  return res;
};
