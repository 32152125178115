import axios from 'src/utils/axios';

export const getCompanyInfo = async () => {
  const response = await axios.get(`edmu/company`);

  const companyInfo = response.data;
  return companyInfo;
};

export const createNewCompany = async (
  name,
  brn,
  contactEmail,
  contactNumber,
  address,
  addressDetail,
  zipCode,
  lat,
  lon,
  homepageUrl,
  companyPassword,
  countryCode,
) => {
  const response = await axios.post(`edmu/company`, {
    name: name,
    contactNumber: contactNumber,
    contactEmail: contactEmail,
    businessNumber: brn,
    countryCode: countryCode,
    location: {
      address: address,
      addressDetail: addressDetail,
      zipCode: zipCode,
      lat: lat,
      lon: lon,
    },
    confirmPassword: companyPassword,
    newPassword: companyPassword,
    homepageUrl,
  });

  const accessToken = response.data.accessToken;
  return accessToken;
};

export const inviteUser = async (userToken) => {
  const response = await axios.post(`edmu/company/check/invite-url`, {
    token: userToken,
  });

  const accessToken = response.data.accessToken;
  return accessToken;
};

export const addEmployeeToCompany = async (companyId) => {
  const response = await axios.patch(`edmu/user/join/company`, {
    keyValue: companyId,
  });

  const accessToken = response.data.accessToken;
  return accessToken;
};

export const getCompanyInviteLink = async () => {
  const response = await axios.get(`edmu/company/invite-url`);
  return response.data.inviteUrl;
};

export const updateCompanyInfo = async (
  name,
  contactNumber,
  contactEmail,
  businessNumber,
  address,
  addressDetail,
  zipCode,
  lat,
  lon,
  homepageUrl,
  password,
  countryCode,
) => {
  await axios.patch('edmu/company', {
    name: name,
    contactNumber: contactNumber,
    contactEmail: contactEmail,
    businessNumber: businessNumber,
    countryCode: countryCode,
    location: {
      address: address,
      addressDetail: addressDetail,
      zipCode: zipCode,
      lat: lat,
      lon: lon,
    },
    password: password,
    homepageUrl,
  });
};

export const updateCompanyPassword = async (
  companypassword,
  newCompanyPassword,
) => {
  await axios.patch('edmu/company/password', {
    password: companypassword,
    newPassword: newCompanyPassword,
    confirmPassword: newCompanyPassword,
  });
};

export const generateResetCompanyPasswordEmail = async (language) => {
  await axios.post('edmu/user-service/find/company-password', {
    language: language,
  });
};

export const updateCompanyPasswordByEmail = async (
  password,
  verificationCode,
) => {
  await axios.patch('edmu/user-service/check/company-password', {
    newPassword: password,
    confirmPassword: password,
    verificationCode: verificationCode,
  });
};

export const deleteCompanyUser = async (userId) => {
  await axios.delete(`edmu/company/user/${userId}`);
};

export const deleteCompanyUserSelf = async (userId, password) => {
  const response = await axios.post(`edmu/company/user/delete/${userId}`, {
    password: password,
  });
  const accessToken = response.data.accessToken;

  return accessToken;
};

export const updateCompanyUserRole = async (userId, role) => {
  await axios.post('edmu/company/role', {
    id: userId,
    role: role,
  });
};

export const getCompanyUsers = async () => {
  const response = await axios.get(`edmu/user/list`);
  const userList = response.data;
  return userList;
};

export const deleteCompany = async (userPassword, companyPassword) => {
  const response = await axios.post(`edmu/company/delete`, {
    password: companyPassword,
    userPassword: userPassword,
  });

  const accessToken = response.data.accessToken;
  return accessToken;
};
