import { styled, Box, Tabs, Tab, CircularProgress } from '@mui/material';

export const TabBox = styled(Box)(
  () => `
    margin-top: 0.5rem;
    margin-bottom: 1rem;
    padding-left: 1rem;
    border-bottom: 0.1rem solid;
    border-color: #D5D7DB;
    `,
);

export const ScrollTabs = styled(Tabs)(
  () => `
    .MuiTabs-scrollableX {
      overflow-x: auto !important;
    }

    .MuiTabs-indicator {
        box-shadow: none;
    }
    `,
);

export const LoadingBox = styled(Box)(
  () => `
    display: flex;
    justify-content: center;
    `,
);

export const LoadingCircularProgress = styled(CircularProgress)(
  () => `
    height: ${
      (window.innerHeight || document.body.clientHeight) / 1.5
    }px !important;
    display: flex;
    vertical-align: middle;
      `,
);

export const TabLabel = styled(Tab)(
  () => `
    font-size: 0.875rem !important;
      `,
);
