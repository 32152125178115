import { useEffect } from 'react';
import { useStore } from 'src/mobx-store';
import { observer } from 'mobx-react-lite';
import { Box, IconButton, Tooltip, Grid, Typography } from '@mui/material';
import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import HeaderButtons from './Buttons';
import HeaderUserbox from './Userbox';
import { HeaderWrapper, MobileMenuBox, WelcomeMsg } from '../style';

const Header = observer(() => {
  const { utilStore, accountStore, companyStore } = useStore();
  const { sidebarOpen, setSidebarOpen } = utilStore;
  const { account } = accountStore;
  const { companyInfo, getCompanyInfo } = companyStore;

  useEffect(() => getCompanyInfo(), []);

  return (
    <HeaderWrapper
      display="flex"
      alignItems="center"
      justifyContent="space-between"
    >
      <Box display="flex" alignItems="center" justifyContent="flex-start">
        <MobileMenuBox component="span">
          <Tooltip arrow title="메뉴">
            <IconButton
              color="primary"
              onClick={() => setSidebarOpen(!sidebarOpen)}
            >
              {!sidebarOpen ? (
                <MenuTwoToneIcon fontSize="medium" />
              ) : (
                <CloseTwoToneIcon fontSize="medium" />
              )}
            </IconButton>
          </Tooltip>
        </MobileMenuBox>
      </Box>
      <Grid
        container
        display="flex"
        alignItems="center"
        className="header_buttons_grid"
      >
        <WelcomeMsg item sidebarOpen={sidebarOpen}>
          <Typography variant="h4">
            Welcome,{' '}
            {`${account.username}! (${companyInfo.name || account.code})`}
          </Typography>
        </WelcomeMsg>
        <Grid item display="flex">
          <HeaderButtons />
          <HeaderUserbox />
        </Grid>
      </Grid>
    </HeaderWrapper>
  );
});

export default Header;
