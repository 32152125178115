import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  TabBox,
  ScrollTabs,
  LoadingBox,
  LoadingCircularProgress,
  TabLabel,
} from './style';
import { useNavigate } from 'react-router-dom';
import ErrorMessageCard from '../ErrorMessageCard';

const PageTab = ({
  tabList,
  helmetList = null,
  onChangeTabName = undefined,
  renderingByIndex,
  rerenderingDeps,
  prefix = '',
  interpolation = null,
  useScroll = true,
}) => {
  const navigate = useNavigate();
  // 로컬 스토리지 값을 가져와 init 해주거나 없으면 0으로 초기화
  const [selectedTabIndex, setSelectedTabIndex] = useState(
    helmetList ? Number(localStorage.getItem('pageTabIndex')) || 0 : 0,
  );
  const [context, setContext] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    setContext(renderingByIndex(selectedTabIndex));
    if (onChangeTabName) onChangeTabName(helmetList[selectedTabIndex]);
    return () => {
      // 탭이 언마운트되면 로컬 스토리지 0으로 초기화
      if (helmetList) localStorage.setItem('pageTabIndex', 0);
    };
  }, []);

  useEffect(() => {
    if (selectedTabIndex !== -1 && context) {
      setContext(renderingByIndex(selectedTabIndex));
      if (onChangeTabName) onChangeTabName(helmetList[selectedTabIndex]);
    }
  }, [selectedTabIndex, rerenderingDeps]);

  const onTabClickHandler = (tabIndex) => {
    setSelectedTabIndex(tabIndex);
    if (helmetList) {
      if (tabIndex === Number(localStorage.getItem('pageTabIndex')))
        navigate(0);
      localStorage.setItem('pageTabIndex', tabIndex);
    }
  };

  return selectedTabIndex === -1 ? (
    <ErrorMessageCard
      code="E400"
      buttonText="이전 페이지"
      mainContent="존재하지 않는 페이지입니다."
      clickEvent={() => {
        window.history.back();
      }}
    />
  ) : context ? (
    <>
      <TabBox>
        {tabList ? (
          <ScrollTabs
            value={selectedTabIndex}
            variant={useScroll ? 'scrollable' : 'fullWidth'}
            scrollButtons={useScroll ? 'auto' : false}
          >
            {tabList.map((tab, tabIndex) => {
              return (
                <TabLabel
                  label={
                    interpolation
                      ? t(prefix + interpolation.key, {
                          [interpolation.value]: t(tab),
                        })
                      : t(prefix + tab)
                  }
                  key={tab}
                  onClick={() => onTabClickHandler(tabIndex)}
                />
              );
            })}
          </ScrollTabs>
        ) : null}
      </TabBox>
      {context}
    </>
  ) : (
    <LoadingBox>
      <LoadingCircularProgress />
    </LoadingBox>
  );
};

export default PageTab;
