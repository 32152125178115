import axios from 'src/utils/axios';

export const postNewManufacturerLocation = async (
  newManufacturerLocationInfo,
) => {
  try {
    const res = await axios.post(`/edmu/location/manufacturing-company`, {
      name: newManufacturerLocationInfo.name,
      address: newManufacturerLocationInfo.address,
      addressDetail: newManufacturerLocationInfo.addressDetail,
      zipCode: newManufacturerLocationInfo.zipCode,
      lat: newManufacturerLocationInfo.lat,
      lon: newManufacturerLocationInfo.lon,
      countryCode: newManufacturerLocationInfo.countryCode,
    });
    return res;
  } catch (error) {
    return error;
  }
};

export const getManufacturerLocation = async () => {
  try {
    const res = await axios.get(`/edmu/location/manufacturing-company`);
    return res.data;
  } catch (error) {
    return error;
  }
};

export const postManufacturer = async (
  name,
  businessNumber,
  contactNumber,
  contactNumberCountryCode,
  exContactNumber,
  exContactNumberCountryCode,
  locationId,
  exLocationId,
  description,
) => {
  const res = await axios.post(`edmu/manufacturing-companies`, {
    name,
    businessNumber,
    contactNumber,
    contactNumberCountryCode,
    exContactNumber,
    exContactNumberCountryCode,
    locationId,
    exLocationId,
    description,
  });
  return res;
};

export const getAllManufacturer = async () => {
  const res = await axios.get('edmu/manufacturing-companies');
  return res.data;
};

export const getManufacturer = async (manufacturerId) => {
  const res = await axios.get('edmu/manufacturing-companies', {
    params: {
      id: manufacturerId,
    },
  });
  return res.data;
};

export const patchManufacturer = async (modifyInfo) => {
  const res = await axios.patch(
    `edmu/manufacturing-companies?id=${modifyInfo.id}`,
    modifyInfo.data,
  );
  return res;
};

export const deleteManufacturer = async (drawingId) => {
  const res = await axios.delete(
    `edmu/manufacturing-companies?id=${drawingId}`,
  );
  return res;
};
