import socketIOClient from 'socket.io-client';
import { firebaseAuth } from 'src/utils/firebase';
import { signOut } from 'firebase/auth';
import { resetStore } from 'src/mobx-store';

const edmuSocket = socketIOClient(process.env.REACT_APP_SOCKET_IP, {
  transports: ['websocket'],
  upgrade: false,
  path: '/socket.io/edmu',
  auth: {
    token: localStorage.getItem('accessToken'),
  },
});

edmuSocket.on('connect_error', async (err) => {
  if (err.message === 'not authorized') {
    // 로그인 상태가 아니라면 소켓 재연결하지 않음
    if (firebaseAuth.currentUser) {
      const refreshedToken = await getRefreshAccessToken();
      edmuSocket.auth.token = refreshedToken;
      edmuSocket.connect();
    }
  }
});

const getRefreshAccessToken = async () => {
  try {
    const newToken = await firebaseAuth.currentUser.getIdToken(true);
    localStorage.setItem('accessToken', newToken);
    return newToken;
  } catch (error) {
    await signOut(firebaseAuth);
    localStorage.removeItem('accessToken');
    resetStore();
    return null;
  }
};

export { edmuSocket };
