import { makeAutoObservable, runInAction } from 'mobx';
import {
  getDiviceByAll,
  getDeviceDetailInforApi,
  getAllDeviceTypes,
  getStoredDeviceLocation,
  getAllDeviceTags,
  createNewDeviceTag,
  getDeviceLogData,
  getLogEvenTypes,
  deleteDevice,
  updateDevice,
  createNewDeviceLocation,
  updateDeviceLocation,
  addBookmarkDevice,
  deleteBookmarkDevice,
  getBookmarkedDeviceByAll,
  getDisplayCardData,
  getFieldControlKey,
  getFieldControlStatus,
  getDevicesSensors,
  getRouterSlaveDevicesByRouterSerial,
  getWebsocketEventByAll,
  createManyNewDeviceInfo,
  verifyManyDevices,
  getBigQueryApiResult,
  getFirestoreQueryResult,
  createNewDeviceInfo,
  controlDevice,
  getDeviceConnection,
  postDeviceDetailInforApi,
  patchDeviceDetailInfoApi,
} from 'src/api/device';
import moment from 'moment';
import { onSnapshot } from 'firebase/firestore';
import DeviceIconList from 'src/assets/icons/deviceIconList';
import {
  convertTimestampToString,
  getTodayTimeStamp,
} from 'src/utils/dateConverter';
import { CONTROL_STATUS } from 'src/assets/constants';

class DeviceStore {
  accountStore;

  alertStore;

  utilStore;

  deviceInfo = {
    id: null,
    name: null,
    type: null,
    serial: null,
    getDataConfig: null,
    parameter: null,
    firebaseConfig: null,
    routerSerial: null,
    isBookmarked: false,
    isConnected: null,
    lastConnectionTime: null,
  };

  deviceTypes = {
    namesList: [],
    detail: [],
  };

  logEventType = [];

  logEventConfig = {
    eventList: [],
    detail: [],
  };

  storedDeviceAddress = [];

  deviceTags = [];

  devices = [];

  bookmarkedDevices = null;

  slaveDevices = null;

  currentRouteInfo = {
    data: null,
    config: {},
  };

  unconnectedDevices = null;

  BIGQUERY = 1;

  FIRESTORE = 0;

  detailData = {
    bigquery: {
      data: null,
      status: false,
      lastCommTime: '통신상태 확인 중..',
    },
    firestore: {
      data: null,
      status: false,
      lastCommTime: '통신상태 확인 중..',
    },
    infor: {
      data: null,
    },
  };

  multiChartSetting = {
    setting: {
      startDate: '',
      endDate: '',
      interval: '',
    },
    checkedValues: [],
    sensors: [],
  };

  detailDataByCheckedValue = false;

  timelineData = {
    content: [
      {
        date: '',
        details: [],
      },
    ],
    filteredDateList: [],
    activeIndex: 0,
  };

  timelineSetting = {
    checkedValues: ['alert', 'confirm', 'warning', 'notice'],
    selectedDate: {
      start: '',
      end: '',
    },
  };

  controlCardInfo = [];

  controlKeyInfo = {};

  controlValueInfo = {};

  controlCardStatus = {};

  statusCardInfo = [];

  constructor({ accountStore, alertStore, utilStore }) {
    makeAutoObservable(this);
    this.accountStore = accountStore;
    this.alertStore = alertStore;
    this.utilStore = utilStore;
  }

  initDetailData = () => {
    this.detailData.bigquery.data = null;
    this.detailData.firestore.data = null;
    this.detailData.firestore.status = false;
    this.detailData.firestore.lastCommTime = '통신상태 확인 중..';
  };

  setDeviceInfo = (key, newValue) => {
    if (key || newValue) {
      switch (key) {
        case 'type':
          this.deviceInfo.type = newValue;
          break;
        case 'serial':
          this.deviceInfo.serial = newValue;
          this.deviceInfo.routerSerial = this.devices?.find(
            (device) => device.serial === this.deviceInfo.serial,
          ).routerSerial;
          break;
        case 'dataConfig':
          this.deviceInfo.getDataConfig = newValue;
          break;
        case 'parameterInfo':
          this.deviceInfo.parameter = newValue;
          break;

        case 'routerSerial':
          this.deviceInfo.routerSerial = newValue;
          break;

        // temp
        case 'detail-parameter':
          this.deviceInfo.getDataConfig = newValue.dataConfig;
          this.deviceInfo.parameter = newValue.parameterInfo;
          this.deviceInfo.firebaseConfig = newValue.firebaseConfig;
          break;

        default:
          this.deviceInfo = {
            id: newValue.id,
            name: this.devices?.find(
              (device) => device.serial === newValue.serial,
            ).nickname,
            type: newValue.type,
            typeId: newValue.typeId,
            serial: newValue.serial,
            tags: newValue.tags,
            routerSerial: newValue.routerSerial,
            getDataConfig: newValue.dataConfig,
            parameter: newValue.parameterInfo,
            location: newValue.location,
          };
          break;
      }
    }
  };

  initializeDevices = () => {
    this.deviceInfo = {
      name: null,
      type: null,
      serial: null,
      getDataConfig: null,
      parameter: null,
    };

    this.devices = null;

    this.bookmarkedDevices = null;

    this.detailData = {
      bigquery: {
        data: null,
        status: false,
        lastCommTime: '통신상태 확인 중..',
      },
      firestore: {
        data: null,
        status: false,
        lastCommTime: '통신상태 확인 중..',
      },
      infor: {
        data: null,
      },
    };

    this.timelineData = {
      content: [
        {
          date: '',
          details: [],
        },
      ],
      filteredDateList: [],
      activeIndex: 0,
    };

    this.storedDeviceAddress = [];
    this.deviceTypes = {
      namesList: [],
      detail: [],
    };
    this.deviceTags = [];
  };

  initializeTimelineDate = async () => {
    this.setTimelineDate([null, null]);
  };

  setDevices = (devices) => {
    this.devices = devices;
  };

  setBookmarkedDevices = (bookmarkedDevices) => {
    this.bookmarkedDevices = bookmarkedDevices;
  };

  setSlaveDevices = (slaveDevices) => {
    this.slaveDevices = slaveDevices;
  };

  setUnconnectedDevices = (unconnectedDevices) => {
    this.unconnectedDevices = unconnectedDevices.filter(
      (deviceInfo) => deviceInfo.routerSerial === null,
    );
  };

  setTimelineData = (timeLineData) => {
    runInAction(() => {
      this.timelineData = { ...this.timelineData, content: timeLineData };
    });
  };

  setTimelineDateList = (timeLineDateList) => {
    this.timelineData.filteredDateList = timeLineDateList;
  };

  setTimelineDate = (selectedDate) => {
    runInAction(() => {});
    let startDate = selectedDate[0];
    let endDate = selectedDate[1];
    let result = [];
    let curDate = new Date(startDate);

    while (curDate <= new Date(endDate)) {
      result.push(curDate.toISOString().split('T')[0]);
      curDate.setDate(curDate.getDate() + 1);
    }
    runInAction(() => {
      this.timelineSetting = {
        ...this.timelineSetting,
        selectedDate: { start: startDate, end: endDate },
      };

      this.timelineData = {
        ...this.timelineData,
        filteredDateList: result,
      };
    });
  };

  setTimelineActiveIndex = (selectedIndex) => {
    this.timelineData.activeIndex = selectedIndex;
  };

  setTimelineCheckedValues = (checkedValues) => {
    this.timelineSetting.checkedValues = checkedValues;
  };

  setFieldDeviceControlData = (data) => {
    this.controlCardInfo = data;
  };

  setInforData = (newValue) => {
    this.detailData.infor.data = newValue;
  };

  setBigqueryData = (dataCategory, getInfo, newValue) => {
    if (!this.detailData.bigquery.data) this.detailData.bigquery.data = {};

    if (!Object.keys(this.detailData.bigquery.data).includes(dataCategory))
      this.detailData.bigquery.data[dataCategory] = {};

    if (
      this.detailData.bigquery.data?.[dataCategory]?.[
        getInfo?.period || 'noPeriod'
      ]
    ) {
      const prevData =
        this.detailData.bigquery.data[dataCategory]?.[
          getInfo.period || 'noPeriod'
        ];

      Object.keys(prevData).forEach((parameterKey) => {
        if (getInfo.includeNull) {
          prevData[parameterKey].some((measData, dataIndex) => {
            if (measData[1] !== 0 && !measData[1]) {
              const emptyDataIndex = dataIndex;
              if (
                measData[1] !== newValue[parameterKey]?.[emptyDataIndex]?.[1]
              ) {
                this.detailData.bigquery.data[dataCategory]?.[
                  getInfo?.period || 'noPeriod'
                ][parameterKey].splice(
                  emptyDataIndex,
                  1,
                  newValue[parameterKey][emptyDataIndex],
                );
                return true;
              }
              return false;
            }
            return false;
          });
        }

        const lastDataIndex = prevData[parameterKey].length - 1;

        // case 1. 같은 타임스탬프 + 평균값 연산 결과만 다를때
        if (
          prevData[parameterKey]?.[lastDataIndex]?.[1] !==
            newValue[parameterKey]?.[lastDataIndex]?.[1] &&
          prevData[parameterKey]?.[lastDataIndex]?.[0] ===
            newValue[parameterKey]?.[lastDataIndex]?.[0]
        ) {
          this.detailData.bigquery.data[dataCategory][
            getInfo.period || 'noPeriod'
          ][parameterKey][lastDataIndex][1] =
            newValue[parameterKey]?.[lastDataIndex][1];

          return true;
        }
        // case 2. 데이터 길이 자체가 다를 때 (초기)
        if (prevData[parameterKey].length !== newValue[parameterKey].length) {
          this.detailData.bigquery.data[dataCategory][
            getInfo.period || 'noPeriod'
          ][parameterKey] = newValue[parameterKey];
          return true;
        }
        // case 3. 새로운 데이터가 들어왔을 때 (데이터 길이가 다 찬 이후)
        if (
          prevData[parameterKey]?.[lastDataIndex]?.[0] !==
          newValue[parameterKey]?.[lastDataIndex]?.[0]
        ) {
          this.detailData.bigquery.data[dataCategory][
            getInfo.period || 'noPeriod'
          ]?.[parameterKey].shift();
          this.detailData.bigquery.data[dataCategory]?.[
            getInfo.period || 'noPeriod'
          ]?.[parameterKey].push(newValue[parameterKey]?.[lastDataIndex]);
          return true;
        }
        this.detailData.bigquery.data[dataCategory][
          getInfo.period || 'noPeriod'
        ][parameterKey] = newValue[parameterKey];
        return true;
      });
    } else {
      this.detailData.bigquery.data[dataCategory][
        getInfo.period || 'noPeriod'
      ] = Object.keys(newValue).length ? newValue : null;
    }
  };

  setDeviceDetailData = (dbType, newValue) => {
    if (dbType)
      this.detailData.bigquery = { ...this.detailData.bigquery, ...newValue };
    else
      this.detailData.firestore = { ...this.detailData.firestore, ...newValue };
  };

  getDisplayCardData = async (serial) => {
    try {
      const response = await getDisplayCardData(serial);

      // 제어카드에 대한 정보
      this.controlCardInfo = response.control?.cardData ?? [];

      this.controlValueInfo = response.control?.deviceData ?? {};

      // 상태카드에 대한 정보
      this.statusCardInfo = response?.status?.cardData ?? [];

      return true;
    } catch (error) {
      console.error('Error: ', error);
      return [500, { message: 'Encountered a server error' }];
    }
  };

  initializeFieldControlData = async (serial, typeName) => {
    const typeId = this.deviceTypes.detail.find(
      (item) => item.name === typeName,
    ).id;

    // 각 제어키에 대한 정보
    await this.getFieldControlKey(typeId);

    // 각 제어 카드에 대한 상태값
    await this.getFieldControlStatus(serial);

    this.updateAllControlCardStatus();
  };

  getFieldControlKey = async (typeId) => {
    try {
      const response = await getFieldControlKey(typeId);

      if (typeof response === 'object') {
        Object.keys(response).forEach((key) => {
          response[key] = { ...response[key], status: CONTROL_STATUS.IDLE };
        });

        Object.keys(this.controlValueInfo).forEach((key) => {
          if (this.controlValueInfo[key] === null) {
            response[key] = {
              ...response[key],
              status: CONTROL_STATUS.NOT_RECEIVED,
            };
          }
        });

        this.controlKeyInfo = response;
      }

      return true;
    } catch (error) {
      console.error('Error: ', error);
      return [500, { message: 'Encountered a server error' }];
    }
  };

  getFieldControlStatus = async (serial) => {
    try {
      const response = await getFieldControlStatus(serial);

      if (typeof response === 'object' && response !== null) {
        response.forEach(({ key, state: status, controlValue }) => {
          if (
            this.controlKeyInfo[key]?.status !== CONTROL_STATUS.NOT_RECEIVED
          ) {
            this.controlKeyInfo[key].status = status;
          }

          if (status === CONTROL_STATUS.LOADING) {
            this.controlValueInfo[key] = controlValue ?? '';
          }
        });
      }

      return true;
    } catch (error) {
      console.error('Error: ', error);
      return [500, { message: 'Encountered a server error' }];
    }
  };

  // 웹 소켓에 대한 응답처리
  applyControlCardStatusChanges = (data, type) => {
    let cardIds = [];

    if (Array.isArray(data)) {
      switch (type) {
        case CONTROL_STATUS.LOADING:
          data.forEach(({ key, newValue }) => {
            if (this.controlKeyInfo[key] !== undefined) {
              this.controlKeyInfo[key].status = type;
              cardIds.push(this.controlKeyInfo[key].cardId);
            }

            if (this.controlKeyInfo[key] !== undefined) {
              this.controlValueInfo[key] = newValue;
            }
          });

          break;

        case CONTROL_STATUS.TIMEOUT:
          data.forEach(({ key }) => {
            if (this.controlKeyInfo[key] !== undefined) {
              this.controlKeyInfo[key].status = type;
              cardIds.push(this.controlKeyInfo[key].cardId);
            }
            this.alertStore.setAlertOpen('error', '장비 제어 실패', {
              interpolation: {
                keyName: this.controlKeyInfo[key].title,
              },
            });
          });

          break;

        case CONTROL_STATUS.IDLE:
          data.forEach(({ key }) => {
            if (
              this.controlValueInfo[key] !== undefined &&
              this.detailData.firestore.data.control?.[key] &&
              this.detailData.firestore.data.control?.[key].length > 0
            ) {
              const lastIndex =
                this.detailData.firestore.data.control[key].length - 1;
              this.detailData.firestore.data.control[key][lastIndex][1] =
                this.controlValueInfo[key];
            }

            if (this.controlKeyInfo[key] !== undefined) {
              this.controlKeyInfo[key].status = type;
              cardIds.push(this.controlKeyInfo[key].cardId);
            }
            this.alertStore.setAlertOpen('success', '장비 제어 성공', {
              interpolation: {
                keyName: this.controlKeyInfo[key].title,
              },
            });
          });
          break;
        default:
          console.log('Invalid type');
          break;
      }

      if (cardIds && Array.isArray(cardIds)) {
        cardIds.forEach((id) => this.updateControlCardStatusById(id));
      }
    }
  };

  updateAllControlCardStatus = () => {
    const statusByCardId = {};

    Object.entries(this.controlKeyInfo).forEach(([, { cardId, status }]) => {
      if (!statusByCardId[cardId]) {
        statusByCardId[cardId] = [];
      }
      statusByCardId[cardId].push(status);
    });

    const result = {};
    Object.entries(statusByCardId).forEach(([cardId, statuses]) => {
      if (statuses.includes(CONTROL_STATUS.NOT_RECEIVED)) {
        result[cardId] = CONTROL_STATUS.NOT_RECEIVED;
      } else if (statuses.includes(CONTROL_STATUS.LOADING)) {
        result[cardId] = CONTROL_STATUS.LOADING;
      } else if (statuses.includes(CONTROL_STATUS.TIMEOUT)) {
        result[cardId] = CONTROL_STATUS.TIMEOUT;
      } else {
        result[cardId] = CONTROL_STATUS.IDLE;
      }
    });

    this.controlCardStatus = result;
  };

  updateControlCardStatusById = (cardId) => {
    const statuses = Object.values(this.controlKeyInfo)
      .filter((item) => item.cardId === cardId)
      .map((item) => item.status);

    let newStatus;
    if (statuses.includes(CONTROL_STATUS.NOT_RECEIVED)) {
      newStatus[cardId] = CONTROL_STATUS.NOT_RECEIVED;
    } else if (statuses.includes(CONTROL_STATUS.LOADING)) {
      newStatus = CONTROL_STATUS.LOADING;
    } else if (statuses.includes(CONTROL_STATUS.TIMEOUT)) {
      newStatus = CONTROL_STATUS.TIMEOUT;
    } else {
      newStatus = CONTROL_STATUS.IDLE;
    }

    this.controlCardStatus[cardId] = newStatus;
  };

  forceCardStatusToIdleById = (cardId) => {
    this.controlCardStatus[cardId] = CONTROL_STATUS.IDLE;
  };

  getBigqueryDeviceDetailData = async () => {
    try {
      const dateFormatting = (type = 'current', dateFormat = null) => {
        const dateFormatResult = dateFormat
          ? `${this.accountStore.account.dateFormat.string}${dateFormat}`
          : this.accountStore.account.dateFormat.string;
        if (type.includes('+') || type.includes('-')) {
          const PERIOD = 0;
          const VALUE = 1;
          if (type.includes('+')) {
            const adjustment = type.split('+');
            return moment()
              .add(adjustment[VALUE], adjustment[PERIOD])
              .format(dateFormatResult);
          }
          const adjustment = type.split('-');
          return moment()
            .subtract(adjustment[VALUE], adjustment[PERIOD])
            .format(dateFormatResult);
        }

        if (type === 'start') {
          return moment().startOf('month').format(dateFormatResult);
        }
        if (type === 'end') {
          return moment().endOf('month').format(dateFormatResult);
        }
        return moment().format(dateFormatResult);
      };
      if (this.deviceInfo.getDataConfig) {
        Object.keys(this.deviceInfo.getDataConfig).forEach((dataTypeKey) => {
          this.deviceInfo.getDataConfig[dataTypeKey]?.forEach(
            async (getInfo) => {
              await getBigQueryApiResult({
                deviceSerial: this.deviceInfo.serial,
                start: dateFormatting(
                  getInfo.statDate.type,
                  getInfo.statDate.format,
                ),
                end: dateFormatting(
                  getInfo.endDate.type,
                  getInfo.endDate.format,
                ),
                type: dataTypeKey,
                data: getInfo.parameter,
                period: getInfo.period,
                processType: getInfo.processType,
                timezone: this.accountStore.account.timeZone,
                limit: getInfo.limit,
                sortType: getInfo.sortType,
                includeNull: getInfo.includeNull,
                reverse: getInfo.reverse,
              }).then((bigqueryApiResponse) => {
                if (Object.keys(bigqueryApiResponse).length) {
                  this.setBigqueryData(
                    dataTypeKey,
                    getInfo,
                    bigqueryApiResponse,
                  );
                }
              });
            },
          );
        });
      }

      // TODO : 통신상태를 소켓, 초기 컴포넌트 상태확인 api로부터 바로 업데이트하기때문에 불필요하게 내부 필드를 업데이트 하지 않도록 주석처리합니다.
      // this.getDeviceCommStatusByDB(this.deviceInfo.serial, this.BIGQUERY);
      return false;
    } catch (error) {
      console.error('Error: ', error);
      return [500, { message: 'Encountered a server error' }];
    }
  };

  getMultiChartDeviceSensors = async (typeName) => {
    const typeId = this.deviceTypes.detail.find(
      (item) => item.name === typeName,
    ).id;

    const chartParameter = await getDevicesSensors(typeId);
    this.multiChartSetting = {
      ...this.multiChartSetting,
      sensors: chartParameter,
    };
  };

  setMultiChartSetting = async (dateRange, interval) => {
    this.multiChartSetting = {
      ...this.multiChartSetting,
      setting: {
        startDate: dateRange.startDate,
        endDate: dateRange.endDate,
        interval: interval,
      },
    };
  };

  setMultiChartCheckedValues = (checkbox) => {
    this.multiChartSetting = {
      ...this.multiChartSetting,
      checkedValues: checkbox.checkedSensors,
    };
  };

  getBigqueryDeviceDetailDataByMultiParameters = async (
    dateRange,
    interval,
  ) => {
    if (this.deviceInfo.serial) {
      const bigqueryApiResponse = await getBigQueryApiResult({
        deviceSerial: this.deviceInfo.serial,
        start: convertTimestampToString(dateRange.startDate * 1000, {
          dateOnly: true,
        }),
        end: convertTimestampToString(dateRange.endDate * 1000, {
          dateOnly: true,
        }),
        type: 'sensors',
        data: this.multiChartSetting.sensors.join(','),
        period: interval,
        processType: 'apexChart',
        timezone: this.accountStore.account.timeZone,
        limit: null,
        sortType: 'desc',
        includeNull: false,
        reverse: false,
      });

      this.detailData.bigquery.data = this.detailData.bigquery.data || {};
      this.detailData.bigquery.data.sensors =
        this.detailData.bigquery.data.sensors || {};

      this.detailData = {
        ...this.detailData,
        bigquery: {
          ...this.detailData.bigquery,
          data: {
            ...this.detailData.bigquery.data,
            sensors: {
              ...this.detailData.bigquery.data.sensors,
              multi: bigqueryApiResponse,
            },
          },
        },
      };
      // TODO : 통신상태를 소켓, 초기 컴포넌트 상태확인 api로부터 바로 업데이트하기때문에 불필요하게 내부 필드를 업데이트 하지 않도록 주석처리합니다.
      // this.getDeviceCommStatusByDB(this.deviceInfo.serial, this.BIGQUERY);
      this.parseDetailDataByCheckedValue();
    }
  };

  initMultiChartData = async (typeName) => {
    this.detailDataByCheckedValue = false;
    const todayTimeStamp = getTodayTimeStamp();
    await this.getMultiChartDeviceSensors(typeName);
    await this.setMultiChartSetting(
      {
        startDate: todayTimeStamp,
        endDate: todayTimeStamp,
      },
      1,
    );
    await this.setMultiChartCheckedValues({
      checkedSensors: this.multiChartSetting.sensors.slice(0, 2),
    });
    await this.getBigqueryDeviceDetailDataByMultiParameters(
      {
        startDate: todayTimeStamp,
        endDate: todayTimeStamp,
      },
      1,
    );
  };

  parseDetailDataByCheckedValue = () => {
    const filteredSensors = {};

    if (typeof this.detailData.bigquery.data.sensors?.multi === 'object') {
      Object.entries(this.detailData.bigquery.data.sensors.multi).forEach(
        ([key, value]) => {
          if (this.multiChartSetting.checkedValues.includes(key)) {
            filteredSensors[key] = value;
          }
        },
      );

      const newData = {
        bigquery: {
          data: {
            sensors: {
              multi: filteredSensors,
            },
            status: this.detailData.bigquery.status,
            lastCommTime: this.detailData.bigquery.lastCommTime,
          },
        },
      };

      this.detailDataByCheckedValue = newData;
    }
  };

  getFirestoreDeviceDetailData = async () => {
    try {
      await getFirestoreQueryResult(this.deviceInfo).then(
        (firestoreQueryRes) => {
          if (!firestoreQueryRes.length) {
            this.setDeviceDetailData(this.FIRESTORE, {
              data: {},
            });
            this.setDeviceDetailData(this.FIRESTORE, {
              status: false,
            });
            this.setDeviceDetailData(this.FIRESTORE, {
              lastCommTime: '통신확인 설정 필요',
            });
          } else {
            firestoreQueryRes.forEach((byEachKeyDetailData) => {
              onSnapshot(
                byEachKeyDetailData,
                { includeMetadataChanges: true },
                (snapshot) => {
                  /*
                    snapshot.metadata.fromCache
                    snapshot.metadata.hasPendingWrites
                    snapshot.docChanges()
                  */
                  const dataCategory =
                    snapshot.query._query.path.segments[
                      snapshot.query._query.path.segments.length - 1
                    ];
                  const snapshotResult = snapshot.docs.map((doc) => {
                    if (doc.data().deviceId === this.deviceInfo.serial) {
                      return {
                        id: doc.id,
                        docData: doc.data(),
                      };
                    }
                    throw new Error('incorrect device id');
                  });
                  if (snapshotResult.length) {
                    const prevData =
                      this.detailData.firestore.data?.[dataCategory];

                    if (prevData) {
                      runInAction(() => {
                        Object.keys(snapshotResult?.[0]?.docData?.data).some(
                          (parameterKey) => {
                            if (
                              snapshotResult?.length !==
                              prevData[parameterKey].length
                            ) {
                              snapshotResult.forEach((snapshotData, index) => {
                                Object.keys(snapshotData.docData.data).forEach(
                                  (parameterKey) => {
                                    if (index === 0) {
                                      this.detailData.firestore.data[
                                        dataCategory
                                      ][parameterKey] = [];
                                    }
                                    this.detailData.firestore.data[
                                      dataCategory
                                    ][parameterKey]?.unshift([
                                      new Date(
                                        snapshotData.docData.timestamp * 1000,
                                      ).getTime(),
                                      snapshotData.docData.data[parameterKey],
                                    ]);
                                  },
                                );
                              });
                            }

                            if (
                              this.detailData.firestore.data[dataCategory][
                                parameterKey
                              ][
                                this.detailData.firestore.data[dataCategory][
                                  parameterKey
                                ].length - 1
                              ][0] !==
                              snapshotResult[0].docData.timestamp * 1000
                            ) {
                              runInAction(() => {
                                this.detailData.firestore.data[dataCategory][
                                  parameterKey
                                ].shift();
                                this.detailData.firestore.data[dataCategory][
                                  parameterKey
                                ].push([
                                  new Date(
                                    snapshotResult[0].docData.timestamp * 1000,
                                  ).getTime(),
                                  snapshotResult[0].docData.data[parameterKey],
                                ]);
                                return true;
                              });
                            }
                            return false;
                          },
                        );
                      });
                    } else {
                      runInAction(() => {
                        if (!this.detailData.firestore.data)
                          this.detailData.firestore.data = {};

                        this.detailData.firestore.data[dataCategory] = {};

                        snapshotResult.forEach((snapshotData, index) => {
                          Object.keys(snapshotData.docData.data).forEach(
                            (parameterKey) => {
                              if (index === 0) {
                                this.detailData.firestore.data[dataCategory][
                                  parameterKey
                                ] = [];
                              }
                              this.detailData.firestore.data[dataCategory][
                                parameterKey
                              ]?.unshift([
                                new Date(
                                  snapshotData.docData.timestamp * 1000,
                                ).getTime(),
                                snapshotData.docData.data[parameterKey],
                              ]);
                            },
                          );
                        });
                      });
                    }
                    // TODO : 통신상태를 소켓, 초기 컴포넌트 상태확인 api로부터 바로 업데이트하기때문에 불필요하게 내부 필드를 업데이트 하지 않도록 주석처리합니다.
                    // this.getDeviceCommStatusByDB(this.deviceInfo.serial, this.BIGQUERY);

                    // if (firestoreQueryRes.length - 1 === byEachKeyIndex) {
                    //   this.getDeviceCommStatusByDB(
                    //     this.deviceInfo.serial,
                    //     this.FIRESTORE,
                    //   );
                    // }
                  }
                  // TODO : 처음 데이터를 불러올때 연속해서 파이어베이스 데이터 + 빈 데이터가 날아와서 빈데이터로 덮어씌워지는 문제 해결을 위한 초기화 주석처리 작업입니다.
                  //  else {
                  //   this.setDeviceDetailData(this.FIRESTORE, {
                  //     data: {},
                  //   });
                  //   this.setDeviceDetailData(this.FIRESTORE, {
                  //     status: false,
                  //   });
                  //   this.setDeviceDetailData(this.FIRESTORE, {
                  //     lastCommTime: '통신확인 설정 필요',
                  //   });
                  // }
                },
                (error) => {
                  /* firestore에 연동을 하기위한 권한에 문제가 있거나, query문 조합을 잘못 한 경우 */
                  this.setDeviceDetailData(this.FIRESTORE, {
                    data: {},
                  });
                  this.setDeviceDetailData(this.FIRESTORE, {
                    status: false,
                  });
                  this.setDeviceDetailData(this.FIRESTORE, {
                    lastCommTime: '통신확인 설정 필요',
                  });
                  return error;
                },
              );
            });
          }
        },
      );

      return true;
    } catch (error) {
      return [500, { message: error }];
    }
  };

  getTimelineData = async (deviceSerial) => {
    try {
      const res = await getDeviceLogData(
        this.timelineSetting,
        deviceSerial,
        this.accountStore.account.timeZone,
      );

      const timeLineRes = res;
      this.setTimelineData(timeLineRes);
      return true;
    } catch (error) {
      console.error('Error: ', error);
      return [500, { message: 'Encountered a server error' }];
    }
  };

  getDevices = async () => {
    try {
      const response = await getDiviceByAll();
      this.setDevices(response);
      return true;
    } catch (error) {
      console.error('Error: ', error);
      return [500, { message: 'Encountered a server error' }];
    }
  };

  getBookmarkedDevices = async () => {
    try {
      const response = await getBookmarkedDeviceByAll();
      this.setBookmarkedDevices(response);
      return true;
    } catch (error) {
      console.error('Error: ', error);
      this.setBookmarkedDevices([]);
      return [500, { message: 'Encountered a server error' }];
    }
  };

  getRouterSlaveDevices = async (routerSerial) => {
    try {
      const response = await getRouterSlaveDevicesByRouterSerial(routerSerial);
      runInAction(() => {
        this.setSlaveDevices(response);
        this.currentRouteInfo.data = response.find(
          (device) => device.type === 'router',
        );
      });

      return true;
    } catch (error) {
      console.error('Error: ', error);
      this.setSlaveDevices([]);
      return [500, { message: 'Encountered a server error' }];
    }
  };

  // TODO : 파이어베이스에서 최신상태에 해당하는 라우터정보를 가져오는 함수입니다.
  // 사실 최신 데이터 하나의 정보만 파이어베이스에서 가져오도록 쿼리문을 동적으로 수정하는게 적합하다고 생각합니다.

  setRouterConfig = async () => {
    return new Promise((resolve) => {
      const routerConfig = this.detailData.firestore.data?.control;

      const getLastValue = (key) => {
        const configArray = routerConfig?.[key];
        return configArray?.[configArray.length - 1]?.[1];
      };

      const configKeys = [
        'portSetting',
        'serverUrl',
        'serverCommPeriod',
        'serverTimeout',
        'serverRetryCount',
        'slave',
      ];

      runInAction(() => {
        configKeys.forEach((key) => {
          this.currentRouteInfo.config[key] = getLastValue(key);
        });

        this.currentRouteInfo.config.extractedPortIds =
          this.currentRouteInfo.config?.portSetting?.map((data) => {
            return Number(data.id);
          });
      });

      resolve();
    });
  };

  updateSlaveDevicesStatus = async (idx, status) => {
    let copySlaveDeviceArray = [...this.slaveDevices];
    copySlaveDeviceArray[idx] = {
      ...copySlaveDeviceArray[idx],
      isConnected: status.isConnected,
      lastConnectionTime: status.lastConnectionTime,
    };
    runInAction(() => {
      this.slaveDevices = copySlaveDeviceArray;
    });
  };

  getUnconnectedDevices = async () => {
    try {
      const response = await getDiviceByAll();
      this.setUnconnectedDevices(response);
      return true;
    } catch (error) {
      console.error('Error: ', error);
      return [500, { message: 'Encountered a server error' }];
    }
  };

  addBookmarkDevice = async (deviceId) => {
    try {
      await addBookmarkDevice(deviceId);
      const updatedDevices = this.devices.map((device) => {
        if (device.id === deviceId) {
          const updatedBookmarkedDevices = [...this.bookmarkedDevices, device];
          this.setBookmarkedDevices(
            updatedBookmarkedDevices.sort(
              (sourceDevice, targetDevice) =>
                sourceDevice.serial - targetDevice.serial,
            ),
          );

          return {
            ...device,
            isBookmarked: !device.isBookmarked,
          };
        }
        return device;
      });
      this.setDevices(updatedDevices);
      this.alertStore.setAlertOpen('success', '즐겨찾기 등록에 성공하였습니다');
      return true;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      throw error;
    }
  };

  deleteBookmarkDevice = async (deviceId) => {
    try {
      await deleteBookmarkDevice(deviceId);
      const updatedDevices = this.devices.map((device) => {
        if (device.id === deviceId) {
          return {
            ...device,
            isBookmarked: !device.isBookmarked,
          };
        }
        return device;
      });
      this.setDevices(updatedDevices);

      if (this.bookmarkedDevices) {
        const updatedBookmarkDevices = this.bookmarkedDevices.filter(
          (device) => device.id !== deviceId,
        );
        this.setBookmarkedDevices(updatedBookmarkDevices);
      }
      this.alertStore.setAlertOpen('success', '즐겨찾기 해제에 성공하였습니다');
      return true;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      throw error;
    }
  };

  getTimelineByDate = (date) => {
    return this.timelineData.content.find(
      (timelineList) => timelineList.date === date,
    );
  };

  getLogTableData = async (deviceSerial) => {
    try {
      const res = await getDeviceLogData(
        {
          checkedValues: ['alert', 'confirm', 'warning', 'notice'],
          selectedDate: {
            start: this.timelineSetting.selectedDate.start,
            end: this.timelineSetting.selectedDate.end,
          },
        },
        deviceSerial,
        this.accountStore.account.timeZone,
      );

      let logTableRows = [];
      // res.map((dateDetail) => {
      //   return dateDetail.details.map((detail) => {
      //     return logTableRows.push({
      //       date: dateDetail.date,
      //       time: detail.time,
      //       type: detail.type,
      //       title: detail.title,
      //       content: detail.content,
      //     });
      //   });
      // });

      res.logData.map((data) => {
        return logTableRows.push({
          date: data.deviceTimestamp,
          deviceType: data.deviceData.type,
          deviceName: data.deviceData.nickname,
          deviceSerial: data.deviceId,
          type: data.alertType,
          logType: data.logType,
          eventCode: data.eventCode,
          content: data.message,
        });
      });
      return logTableRows;
    } catch (error) {
      console.error('Error: ', error);
      return [500, { message: 'Encountered a server error' }];
    }
  };

  getLogEvenTypes = async () => {
    try {
      const res = await getLogEvenTypes();
      const allLogEvents = [].concat(...Object.values(res));

      runInAction(() => {
        this.logEventConfig.detail = res;
        this.logEventConfig.eventList = allLogEvents;
      });
      return true;
    } catch (error) {
      console.error('Error: ', error);
      return [500, { message: 'Encountered a server error' }];
    }
  };

  getSideBarDeviceList = () => {
    if (
      Array.isArray(this.bookmarkedDevices) &&
      !this.bookmarkedDevices.length
    ) {
      return [
        {
          name: '장비를 등록해 주세요',
          link: `monitoring/device-list`,
          hasParent: true,
          hasChildren: true,
        },
      ];
    }
    let sidebarDeviceList = [];
    this.bookmarkedDevices.forEach((device) => {
      if (isNotInclude(device.type)) {
        sidebarDeviceList.push({
          name: device.type,
          link: `monitoring/device/${device.type}`,
          icon: DeviceIconList[device.type],
          items: [],
          hasParent: true,
          hasChildren: true,
        });
      }
      sidebarDeviceList
        .find((list) => list.name === device.type)
        .items.push({
          name: `[${device.serial}]${device.nickname}`,
          link: `monitoring/device/${device.type}/${device.serial}`,
          hasParent: true,
          hasChildren: false,
        });
    });

    function isNotInclude(type) {
      let flag = true;
      sidebarDeviceList.forEach((data) => {
        if (data.name === type) {
          flag = false;
        }
      });

      return flag;
    }

    return sidebarDeviceList;
  };

  // eslint-disable-next-line consistent-return
  getDeviceListByProperty = (propertyName) => {
    if (!this.devices || !Array.isArray(this.devices) || !this.devices.length) {
      return [];
    }

    return this.devices.reduce((list, device) => {
      if (!list.includes(device[propertyName])) {
        list.push(device[propertyName]);
      }
      return list;
    }, []);
  };

  patchDeviceInfo = async (modifyInfo) => {
    try {
      const res = await updateDevice(modifyInfo);
      if (res.code) throw res;
      return true;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return false;
    }
  };

  deleteDeviceById = async (selectedDeivces, companyPassword) => {
    try {
      const deleteDeviceTasks = selectedDeivces.map((deviceInfo) => {
        return deleteDevice(deviceInfo.id, companyPassword);
      });
      return Promise.all(deleteDeviceTasks)
        .then(() => {
          this.getDevices();
          this.getBookmarkedDevices();
          this.alertStore.setAlertOpen('success', `장비 삭제를 완료했습니다.`);
          return true;
        })
        .catch((error) => {
          this.alertStore.setAlertOpen('error', error);
          return false;
        });
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return false;
    }
  };

  getDeviceTypes = async () => {
    try {
      const res = await getAllDeviceTypes();
      this.deviceTypes = {
        namesList: res?.map((device) => device.name),
        detail: res,
      };
      return true;
    } catch (error) {
      console.error('Error: ', error);
      return [500, { message: 'Encountered a server error' }];
    }
  };

  getStoredAddress = async () => {
    try {
      const res = await getStoredDeviceLocation();
      runInAction(() => {
        this.storedDeviceAddress = res;
      });
      return true;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return false;
    }
  };

  getDeviceTags = async () => {
    try {
      const res = await getAllDeviceTags();
      runInAction(() => {
        this.deviceTags = res;
      });
      return true;
    } catch (error) {
      console.error('Error: ', error);
      return [500, { message: 'Encountered a server error' }];
    }
  };

  submitNewDeviceTag = async (newTags) => {
    try {
      const makeTagStr = await newTags.map(async (newTag) => {
        if (!newTag.id) {
          return createNewDeviceTag(newTag.name);
        }
        return newTag.id;
      });
      return await Promise.all(makeTagStr).then((data) => {
        return data.toString();
      });
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return error;
    }
  };

  submitNewDeviceLocation = async (newAddress) => {
    try {
      const id = await createNewDeviceLocation(newAddress).then((res) => {
        this.alertStore.setAlertOpen(
          'success',
          '새로운 주소 추가가 완료되었습니다.',
        );
        if (res.code) throw res;
        return res.data.id;
      });
      return id;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return error;
    }
  };

  updateDeviceLocation = async (newLocation) => {
    try {
      const res = await updateDeviceLocation(newLocation);
      if (res.code) throw res;
      return res;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return error;
    }
  };

  // eslint-disable-next-line class-methods-use-this
  checkNewDevices = async (newDeviceInfos, useAlertInfo = false) => {
    try {
      const parsedNewDeviceInfos = newDeviceInfos.map(([serial, nickname]) => ({
        serial,
        nickname,
      }));
      await verifyManyDevices(parsedNewDeviceInfos);
      return null;
    } catch (error) {
      if (useAlertInfo) this.alertStore.setAlertOpen('error', error);
      if (error.response && error.response.data) {
        return error.response.data.map((obj) => {
          let newKey = obj.key;
          if (newKey === 'nickname') newKey = 'name';
          if (newKey === 'serial') newKey = 'serialNumber';

          return {
            ...obj,
            key: newKey,
          };
        });
      }

      return null;
    }
  };

  submitNewDevices = async (newDeviceInfos) => {
    try {
      const parsedNewDeviceInfos = newDeviceInfos.map(
        ({
          serialNumber: serial,
          name: nickname,
          location: { id: locationId },
        }) => ({
          serial,
          nickname,
          locationId,
        }),
      );

      await createManyNewDeviceInfo(parsedNewDeviceInfos);
      this.alertStore.setAlertOpen('success', '장비 추가에 성공하였습니다');
      return null;
    } catch (error) {
      const errorIndices = new Set();

      error.response.data = error.response.data.map((obj) => {
        let newKey = obj.key;
        if (newKey === 'nickname') newKey = 'name';
        if (newKey === 'serial') newKey = 'serialNumber';

        return {
          ...obj,
          key: newKey,
        };
      });

      error.response.data.forEach((invalidRow) => {
        invalidRow.value.forEach((index) => {
          errorIndices.add(index);
        });
      });

      const sortedIndices = Array.from(errorIndices).sort((a, b) => a - b);
      const errorIndexList = sortedIndices
        .map((index, i, arr) =>
          i === arr.length - 1 ? `${index}` : `${index},`,
        )
        .join('');

      this.alertStore.setAlertOpen('error', '다중 장비 등록 에러', {
        interpolation: {
          invalidRow: errorIndexList,
        },
      });

      throw error.response.data;
    }
  };

  submitNewDeviceInfo = async (newDeviceInfo) => {
    try {
      await createNewDeviceInfo(newDeviceInfo).then((res) => {
        if (res.response) {
          throw res;
        }
        this.alertStore.setAlertOpen('success', '장비 추가에 성공하였습니다');
      });
      return true;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return false;
    }
  };

  submitDeviceControl = async (serial, password, controlData) => {
    try {
      // FIXME 때때로 serial이 undefiend인 값이 DB에 저장되어있는데 해당 상황을 파악하기 위한 에러메세지입니다.
      if (serial === null && serial === undefined) {
        this.alertStore.setAlertOpen(
          'error',
          'serial이 할당되지 않은 장비입니다.',
        );
      }
      const res = await controlDevice(serial, password, controlData);
      if (res.response) {
        throw res;
      }
      this.alertStore.setAlertOpen('success', '장비 제어 명령을 요청했습니다.');
      return true;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return error.response.data[0]?.code;
    }
  };

  getDeviceCommStatusByDB = async (deviceSerial, type) => {
    try {
      if (!deviceSerial) return;
      const res = await getDeviceConnection(deviceSerial);
      if (res.status && !(res.status === 200 || res.status === 201)) {
        throw new Error(res.response.data);
      }
      runInAction(() => {
        if (type === this.BIGQUERY) {
          this.detailData.bigquery.status = res.isConnected;
          this.detailData.bigquery.lastCommTime = moment(
            res.lastConnectionTime * 1000,
          ).format('YYYY-MM-DD HH:mm');
        } else {
          this.detailData.firestore.status = res.isConnected;
          this.detailData.firestore.lastCommTime = moment(
            res.lastConnectionTime * 1000,
          ).format('YYYY-MM-DD HH:mm');
        }
      });
    } catch {
      runInAction(() => {
        if (type === this.BIGQUERY) {
          this.detailData.bigquery.status = false;
          this.detailData.bigquery.lastCommTime = '통신확인 설정 필요';
        } else {
          this.detailData.firestore.status = false;
          this.detailData.firestore.lastCommTime = '통신확인 설정 필요';
        }
      });
    }
  };

  setRouterCommStatus = async (commStatusInfo) => {
    try {
      // 테스트: communication info 출력용
      runInAction(() => {
        this.deviceInfo.isConnected = commStatusInfo.isConnected;
        this.deviceInfo.lastConnectionTime =
          commStatusInfo.lastConnectionTime ||
          this.deviceInfo.lastConnectionTime;
      });
      return true;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return false;
    }
  };

  getDeviceCommStatus = async (deviceSerial) => {
    try {
      if (!deviceSerial) return false;
      const res = await getDeviceConnection(deviceSerial);
      if (res.code) throw res;
      this.setRouterCommStatus(res);
      return true;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return false;
    }
  };

  updateDevicesStatus = async (idx, status) => {
    let copyDeviceListArray = [...this.devices];
    copyDeviceListArray[idx] = {
      ...copyDeviceListArray[idx],
      isConnected: status.isConnected,
      lastConnectionTime: status.lastConnectionTime,
    };
    runInAction(() => {
      this.devices = copyDeviceListArray;
    });
  };

  getWebsocketEvnet = async (deviceSerial) => {
    try {
      const res = await getWebsocketEventByAll(deviceSerial);
      return res;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return false;
    }
  };

  createDeviceDetailInfo = async (newInfo) => {
    try {
      const res = await postDeviceDetailInforApi(newInfo);
      if (res.code) throw res;
      this.alertStore.setAlertOpen('success', '장비 수정을 완료하였습니다');
      return true;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return false;
    }
  };

  updateDeviceDetailInfo = async (modifyInfo) => {
    try {
      const res = await patchDeviceDetailInfoApi(modifyInfo);
      if (res.code) throw res;
      this.alertStore.setAlertOpen('success', '장비 수정을 완료하였습니다');
      return true;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return false;
    }
  };

  getDeviceDetailInfo = async (deviceId) => {
    try {
      const res = await getDeviceDetailInforApi(deviceId);
      if (!res || res.code) throw res;
      this.setInforData(res);
      return true;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return false;
    }
  };
}

export default DeviceStore;
