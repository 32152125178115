import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Tooltip } from '@mui/material';
import palletteIcon from 'src/assets/images/paletteColorIcon.png';
import { useTranslation } from 'react-i18next';
import { ThemeSettingsButtonBox } from './style';

const ThemeSettingsButton = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const ref = useRef(null);

  const handleOpen = () => {
    navigate('/setting/theme');
  };

  return (
    <>
      <ThemeSettingsButtonBox>
        <Tooltip arrow title={t('테마 설정')}>
          <Box ref={ref} onClick={handleOpen} color="primary" aria-label="add">
            <img alt="theme_icon" src={palletteIcon} />
          </Box>
        </Tooltip>
      </ThemeSettingsButtonBox>
    </>
  );
};

export default ThemeSettingsButton;
