const koJSON = {
  /**
   * 사용 범위 : Helmet
   * 업데이트 : 23.07.19
   * 작성자 : 서연주
   */
  info: '정보',
  join: '가입',
  create: '생성',
  user: '사용자',
  디테일탭: {
    main: '메인',
    status: '설비상태',
    control: '현장제어',
    ai: '현장설비예측',
    log: '설비로그',
    setting: '통신장비설정',
  },
  /**
   * 사용 범위 : 장비 리스트 목록 Key변환
   * 업데이트 : 23.05.04
   * 작성자 : 서연주
   */
  // SidebarMenu
  sensors: '계측',
  managements: '상태',
  control: '제어',
  all: '모두',
  'one-phase-solar': '태양광 단상',
  'three-phase-solar': '태양광 삼상',
  fs600r: 'FS-600R',
  router: 'EDMU',
  'temperature-controller': '온도 제어기',
  thermohygrometer: '온습도계',
  'solar-thermal': '태양열',
  'solar-water-heater': '태양열 자연 순환식 온수기',
  geothermal: '지열',
  wind: '풍력',
  'fuel-cell': '연료전지',
  ess: '에너지 저장 시스템',
  'electric-vehicle-charger': '전기차 충전기',
  default: '기본 설비',

  // LogType

  deviceConnection: '장비 연결',
  deviceConnectionTimeout: '연결 시간 초과',
  deviceConnectionError: '연결 오류',
  deviceConnectionUnknown: '알 수 없는 연결',
  deviceControlApply: '제어 적용',
  deviceControlTimeout: '제어 시간 초과',
  deviceControl: '장비 제어',
  deviceBroken: '장비 고장',
  deviceBrokenRestore: '장비 복구',

  /**
   * 사용 범위 : 사이드바 레이아웃(사이드바, 헤더)
   * 업데이트 : 23.07.07
   * 작성자 : 서연주
   */
  // SidebarLayout
  '업그레이드 메세지': '평가판이 {{day}}일 이후에 종료됩니다',

  // Ag Grid Device Info Column
  id: '장비 ID',
  type: '종류',
  nickname: '이름',
  isConnected: '연결 상태',
  modelName: '모델 이름',
  serial: '장비 시리얼',
  firmwareVersion: '펌웨어 버전',
  'model.name': '모델명',
  'location.name': '장비 위치',
  createdAt: '설치 일자',
  routerSerial: '라우터 시리얼',
  function: '기능',
  link: '상세보기',
  lastConnectionTime: '마지막 통신시간',
  operationState: '동작 상태',
  /**
   * 사용 범위 : DeviceDetail 내부 컴포넌트
   * 업데이트 : 23.03.03
   * 작성자 : 서연주
   */

  // LogTable
  notice: '알림',
  confirm: '확인',
  warning: '주의',
  alert: '경보',

  /**
   * 사용 범위 : 그룹설정과 관련된 내부 컴포넌트
   * 업데이트 : 23.03.29
   * 작성자 : 정태원
   */
  '데이터 카드': {
    '월 ': '전월 대비',
    '일 ': '{{dayValue}}일 ',
    '시간 ': '{{hourValue}}시간 ',
    '분 ': '{{minValue}}분 ',
    '초 ': '{{secValue}}초 ',
    '시간 에러': '시간 에러',
    '전 대비': '전 대비',
  },
  '에러코드 처리가 필요합니다.':
    '에러코드 처리가 필요합니다. [CODE : {{errorCode}}]',
  그룹: {
    그룹: '그룹',
    관리자: '관리자',
    사용자: '사용자',
    id: '사용자 ID',
    username: '이름',
    email: '이메일',
    role: '권한',
    admin: '관리자',
    user: '사용자',
    function: '기능',
  },
  제조업체: {
    id: 'ID',
    name: '업체 이름',
    businessNumber: '사업자 등록번호',
    contactNumber: '본사 연락처',
    'location.name': '본사 주소',
    exContactNumber: '공장 연락처',
    'exLocation.name': '공장 주소',
    description: '비고',
    삭제: '삭제',
  },
  '교체/수리': {
    id: 'ID',
    title: '제목',
    'deviceData.nickname': '장비 이름',
    reporterName: '작성자 이름',
    reporterPhone: '작성자 연락처',
    reporterEmail: '작성자 이메일',
    workerName: '작업자 이름',
    workerPhone: '작업자 연락처',
    workerEmail: '작업자 이메일',
    serviceType: '서비스 종류',
    reportDate: '접수 일자',
    dispatchDate: '출동 일자',
    maintenanceDate: '점검 일자',
    issueDescription: '문제 현상',
    actionTaken: '조치 내용',
    description: '비고',
    삭제: '삭제',
    bs: '사전 서비스',
    as: '사후 서비스',
    images: '현장 사진',
    reporterId: '작성자',
    workerId: '작업자',
    'reporter.name': '작성자',
    'worker.name': '작업자',
  },
  정기점검: {
    id: 'ID',
    title: '제목',
    'deviceData.nickname': '장비 이름',
    reporterName: '작성자 이름',
    reporterPhone: '작성자 연락처',
    reporterEmail: '작성자 이메일',
    workerName: '작업자 이름',
    workerPhone: '작업자 연락처',
    workerEmail: '작업자 이메일',
    serviceType: '서비스 종류',
    maintenanceDate: '점검일자',
    description: '비고',
    삭제: '삭제',
    images: '현장 사진',
    checklist: '점검 목록',
    checklistItem: '점검 항목',
    checkContent: '점검 내용',
    checkResult: '결과',
    imageKey: '점검 사진',
    normal: '적합',
    warning: '주의',
    error: '부적합',
    reporterId: '작성자',
    'reporter.name': '작성자',
    workerId: '작업자',
    'worker.name': '작업자',
    status: '상태',
    pending: '대기',
    'in progress': '진행중',
    completed: '완료',
    failed: '실패',
  },
  제품모델: {
    id: 'ID',
    name: '제품명',
    'manufacturingCompany.name': '업체',
    'deviceType.name': '장비 종류',
    model: '모델명',
    description: '비고',
    삭제: '삭제',
  },
  '드롭다운 제목': '{{field}} 변경',
  '(내 계정)': '(내 계정)',

  // device control

  '장비 제어 성공': '[{{keyName}}] 장비 제어에 성공했습니다',

  '장비 제어 실패': '[{{keyName}}] 장비 제어에 실패했습니다',

  '시간 설정': '시간 설정 [{{keyName}}]',
  '예약 설정': '예약 설정 [{{keyName}}]',

  Unassigned: '미할당',
  포트: '포트 {{portNo}}',
  '통신 연결 해제중': '{{deviceNickname}} 장비 통신 연결 해제중...',
  // router page
  protocol: '프로토콜',
  protocolId: '국번',
  portNo: '포트 번호',
  serverUrl: '서버 주소',
  serverCommPeriod: '서버 통신 주기',
  serverTimeout: '서버 타임아웃',
  serverRetryCount: '서버 통신 재시도',
  slaveCommPeriod: 'Slave 통신 주기',
  slaveTimeout: 'Slave 타임아웃',
  slaveRetryCount: 'Slave 통신 재시도',
  timeoutTooltip: '포트 {{portNumber}} {{keyName}}',

  단위: {
    월: '월',
    일: '일',
    시간: '시간',
    분: '분',
  },
  날씨: {
    'thunderstorm with light rain': '약한 비를 동반한 천둥번개',
    'thunderstorm with rain': '보통 비를 동반한 천둥번개',
    'thunderstorm with heavy rain': '강한 비를 동반한 천둥번개',
    'light thunderstorm': '약한 천둥 번개',
    thunderstorm: '천둥 번개',
    'heavy thunderstorm': '강한 천둥 번개',
    'ragged thunderstorm': '불규칙한 천둥 번개',
    'thunderstorm with light drizzle': '약한 이슬비를 동반한 천둥번개',
    'thunderstorm with drizzle': '보통 이슬비를 동반한 천둥번개',
    'thunderstorm with heavy drizzle': '강한 이슬비를 동반한 천둥번개',
    'light intensity drizzle': '약한 이슬비',
    drizzle: '이슬비',
    'heavy intensity drizzle': '강한 이슬비',
    'light intensity drizzle rain': '약한 이슬비',
    'drizzle rain': '이슬비',
    'heavy intensity drizzle rain': '강한 이슬비',
    'shower rain and drizzle': '소나기와 이슬비',
    'heavy shower rain and drizzle': '강한 소나기와 이슬비',
    'shower drizzle': '이슬비',
    'light rain': '약한 비',
    'moderate rain': '비',
    'heavy intensity rain': '강한 비',
    'very heavy rain': '매우 강한 비',
    'extreme rain': '폭우',
    'freezing rain': '빙우',
    'light intensity shower rain': '약한 소나기',
    'shower rain': '소나기',
    'heavy intensity shower rain': '강한 소나기',
    'ragged shower rain': '불규칙한 소나기',
    'light snow': '약한 눈',
    snow: '눈',
    'heavy snow': '강한 눈',
    sleet: '진눈깨비',
    'light shower sleet': '약한 진눈깨비',
    'shower sleet': '진눈꺠비',
    'light rain and snow': '약한 비를 동반한 눈',
    'rain and snow': '비를 동반한 눈',
    'light shower snow': '약한 눈보라',
    'shower snow': '눈보라',
    'heavy shower snow': '강한 눈보라',
    mist: '안개',
    smoke: '스모그',
    haze: '아지랑이',
    'sand/dust whirls': '모래/먼지 회오리',
    fog: '짙은 안개',
    sand: '황사',
    dust: '미세 먼지',
    'volcanic ash': '화산 활동',
    squalls: '스콜',
    tornado: '토네이도',
    'clear sky': '맑음',
    'few clouds': '구름 조금',
    'scattered clouds': '구름 많음',
    'broken clouds': '흐림',
    'overcast clouds': '아주 흐림',
  },
  'YYYY-MM-DD': 'YYYY년 MM월 D일',
  '날짜 및 시간 표시 12시계': '{{dateFormatString}} A hh:mm:ss',
  '날짜 및 시간 표시 24시계': '{{dateFormatString}} HH:mm:ss',

  // 장비 추가

  'duplicate serial': '이미 등록된 시리얼 번호입니다.',
  'duplicate device nickname': '이미 등록된 장비 이름입니다.',
  'invalid type serial': '시리얼 번호가 유효하지 않습니다.',
  'serial is required': '빈 시리얼 번호 입니다.',
  'nickname is required': '빈 장비 이름 입니다.',

  '다중 장비 등록 에러':
    '[{{invalidRow}}번] 행 정보에 에러가 발생해 장비등록에 실패하였습니다. 에러 필드 행을 눌러 수정을 진행해주세요.',
  플레이스홀더: '새로 설정할 {{keyName}} 입력해주세요',
};

export default koJSON;
