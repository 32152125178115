import Guest from 'src/components/Guest';
import { Loader, retryLazy } from 'src/utils/lazyUtil';

// Account

const LoginCover = Loader(retryLazy(() => import('src/pages/account/Login')));
const LoginFirebaseAuth = Loader(
  retryLazy(() =>
    import('src/pages/account/Login/components/LoginFirebaseAuth'),
  ),
);

const RegisterCover = Loader(
  retryLazy(() => import('src/pages/account/Register')),
);

const RecoverEmail = Loader(
  retryLazy(() => import('src/pages/account/RecoverEmail')),
);

const RecoverPassword = Loader(
  retryLazy(() => import('src/pages/account/RecoverPassword')),
);

const accountRoutes = [
  {
    path: 'login',
    element: (
      <Guest>
        <LoginCover />
      </Guest>
    ),
  },
  {
    path: 'register',
    element: (
      <Guest>
        <RegisterCover />
      </Guest>
    ),
  },
  {
    path: 'login-basic',
    element: <LoginFirebaseAuth />,
  },
  {
    path: 'login-cover',
    element: <LoginCover />,
  },
  {
    path: 'recover-email',
    element: (
      <Guest>
        <RecoverEmail />
      </Guest>
    ),
  },
  {
    path: 'recover-password',
    element: (
      <Guest>
        <RecoverPassword />
      </Guest>
    ),
  },
  {
    path: 'register',
    element: (
      <Guest>
        <RegisterCover />
      </Guest>
    ),
  },
  {
    path: 'register-cover',
    element: <RegisterCover />,
  },
];

export default accountRoutes;
