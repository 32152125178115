import { useEffect, useState } from 'react';
import {
  GoogleMap,
  Marker,
  Autocomplete,
  LoadScript,
} from '@react-google-maps/api';
import {
  IconButton,
  Typography,
  Box,
  Grid,
  TextField,
  CircularProgress,
} from '@mui/material';
import { useStore } from 'src/mobx-store';
import { useTranslation } from 'react-i18next';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { LocationMapDiv } from './style';
import './cssStyle.css';

const LocationMap = ({ initLocation = null, setAddress }) => {
  const { t, i18n } = useTranslation();
  const { locationStore } = useStore();
  const [libraries] = useState(['places', 'geocoding']);
  const [searchText, setSearchText] = useState('');
  const [currentCoordinate, setCurrentCoordinate] = useState(null);
  const [currentAddress, setCurrentAddress] = useState(null);
  const [selectedCoordinate, setSelectedCoordinate] = useState(null);
  const [selectedAddress, setselectedAddress] = useState(null);
  const { getReverseGeocodingDataByCoordinates } = locationStore;

  const reqAddrInfo = async (latitude, longitude, init) => {
    const reverseGeocodingRes = await getReverseGeocodingDataByCoordinates(
      latitude,
      longitude,
      i18n.language,
      '|street_address||postal_code||establishment||point_of_interest|',
    );
    setSelectedCoordinate({ lat: latitude, lng: longitude });
    if (reverseGeocodingRes.mainAddress) {
      if (init)
        setCurrentAddress({
          address: reverseGeocodingRes.mainAddress,
          zipCode: reverseGeocodingRes.zipCode,
          countryCode: reverseGeocodingRes.countryCode,
        });
      setselectedAddress({
        address: reverseGeocodingRes.mainAddress,
        zipCode: reverseGeocodingRes.zipCode,
        countryCode: reverseGeocodingRes.countryCode,
      });
    } else {
      setselectedAddress(null);
    }
  };

  const handleSuccess = async (pos) => {
    const { latitude, longitude } = pos.coords;
    setCurrentCoordinate({ lat: latitude, lng: longitude });
    setSelectedCoordinate({ lat: latitude, lng: longitude });
    reqAddrInfo(latitude, longitude, true);
  };

  const handleError = (error) => {
    console.error(error.message);
  };

  const geolocationOptions = {
    enableHighAccuracy: true,
    timeout: 1000 * 60 * 1, // 1 min (1000 ms * 60 sec * 1 minute = 60 000ms)
    maximumAge: 1000 * 3600 * 24, // 24 hour
  };

  useEffect(() => {
    if (initLocation) {
      setCurrentCoordinate({ lat: initLocation.lat, lng: initLocation.lon });
      setSelectedCoordinate({ lat: initLocation.lat, lng: initLocation.lon });
      reqAddrInfo(initLocation.lat, initLocation.lon, true);
    } else {
      const { geolocation } = navigator;
      if (!geolocation) {
        console.error('Geolocation is not supported.');
        return;
      }
      geolocation.getCurrentPosition(
        handleSuccess,
        handleError,
        geolocationOptions,
      );
    }
  }, []);

  const markerClicked = async (e) => {
    const lat = e.latLng.lat();
    const lng = e.latLng.lng();
    reqAddrInfo(lat, lng);
    setSearchText('');
  };

  const [autocompleteRef, setAutocompleteRef] = useState(null);

  const autocompleteOnLoad = (ref) => {
    // The pac-container from the autocomplete isn't removed when leaving the page.
    // Causing the creation of additional containers when navigating back home, leading to bugs.
    // Removing it via the useEffect hook upon unmount also removes it upon hot reload.
    // So we're only removing it here and accept it staying around on other pages.
    const pac = document.getElementsByClassName('pac-container');
    if (pac.length) {
      pac[0].remove();
    }

    setAutocompleteRef(ref);
  };

  const autocompleteOnPlaceChanged = () => {
    const autocompleteRes = autocompleteRef.getPlace();
    // If user pressed enter instead of selecting a suggestion,
    // only { name: <user text input> } is returned.
    // Automatically use the first suggestion.
    if (autocompleteRes.place_id === undefined) {
      const pacItemQuery = document.getElementsByClassName('pac-item-query');
      const autocompleteInput =
        document.getElementsByClassName('pac-target-input')[0];
      // The user pressed return in empty autocomplete: Search anywhere
      if (autocompleteInput.value.trim() === '') {
        // handleSearchAnywhereBtnClick();
        console.error('no results');
        // Get first suggestion and search for that
      } else if (pacItemQuery.length) {
        const next = pacItemQuery[0].nextSibling;
        let inputText = pacItemQuery[0].textContent;
        if (next.textContent !== '') {
          inputText += `, ${next.textContent}`;
        }
        autocompleteInput.value = inputText;
        autocompleteInput.blur();
        const attribution = document.createElement('div');
        const getFirstData = new window.google.maps.places.PlacesService(
          attribution,
        );
        getFirstData.findPlaceFromQuery(
          {
            query: inputText,
            fields: ['name', 'place_id', 'geometry', 'types'],
          },
          (firstData) => {
            if (firstData.length) {
              setSearchText(firstData[0].name);
              const lat = firstData[0].geometry.location.lat();
              const lng = firstData[0].geometry.location.lng();
              reqAddrInfo(lat, lng);
            }
          },
        );
      }
    } else {
      const autocompleteInput =
        document.getElementsByClassName('pac-target-input')[0];
      autocompleteInput.blur();
      setSearchText(autocompleteRes.name);
      const lat = autocompleteRes.geometry.location.lat();
      const lng = autocompleteRes.geometry.location.lng();
      reqAddrInfo(lat, lng);
    }
  };

  return selectedCoordinate ? (
    <LocationMapDiv>
      <LoadScript
        className="pac-container"
        id="script-loader"
        googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
        libraries={libraries}
        key={`map-${i18n.language}`}
        language={i18n.language}
        region={i18n.language}
      >
        <GoogleMap
          id="map"
          center={selectedCoordinate}
          zoom={15}
          mapContainerStyle={{ height: '100%', width: '100%' }}
          options={{
            zoomControl: true,
            streetViewControl: false,
            mapTypeControl: false,
            fullscreenControl: false,
          }}
          onClick={markerClicked}
        >
          <Grid container justifyContent="center">
            <Box className="search_box">
              <Grid item xs={12}>
                <Grid container justifyContent="center">
                  <Grid item xs={10}>
                    <Autocomplete
                      // onPlaceChanged={onPlaceChanged}
                      // onLoad={onLoad}
                      onPlaceChanged={autocompleteOnPlaceChanged}
                      onLoad={autocompleteOnLoad}
                    >
                      <TextField
                        className="search_input"
                        onChange={(data) => setSearchText(data.target.value)}
                        value={searchText}
                        variant="outlined"
                        placeholder={t('지도 검색...')}
                      />
                    </Autocomplete>
                  </Grid>
                  <Grid item>
                    <IconButton
                      className="current_location_button"
                      onClick={() => {
                        setselectedAddress(currentAddress);
                        setSelectedCoordinate(currentCoordinate);
                        setSearchText('');
                      }}
                    >
                      <LocationOnIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container justifyContent="center">
                  <Grid item xs={12}>
                    <Typography className="search_result" variant="h5">
                      {selectedAddress
                        ? selectedAddress.zipCode
                          ? `[${selectedAddress.zipCode}] - ${selectedAddress.address}`
                          : `${selectedAddress.address}`
                        : t('결과 없음')}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <IconButton
                      className="confirm_buttom"
                      onClick={() => {
                        setAddress({
                          ...selectedAddress,
                          ...selectedCoordinate,
                        });
                      }}
                    >
                      <CheckCircleIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Marker position={selectedCoordinate} />
        </GoogleMap>
      </LoadScript>
    </LocationMapDiv>
  ) : (
    <Grid container justifyContent="center">
      <CircularProgress />
    </Grid>
  );
};

export default LocationMap;
