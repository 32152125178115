import { collection, query, where, orderBy, limit } from 'firebase/firestore';
import { firebaseDatabase } from 'src/utils/firebase.js';
import axios from 'src/utils/axios';

export const getDeviceById = async () => {
  const selectedDevice = {
    id: 1234,
    serial: '02135794',
    location: '안산 사무실',
    type: '태양광',
    routerSerial: '123123',
    nickname: '유니로보틱스',
    firmwareVersion: '1.0.0',
  };
  return selectedDevice;
};

export const getDisplayCardData = async (serial) => {
  const response = await axios.get('edmu/device-type/card', {
    params: {
      'device-serial': serial,
    },
  });
  return response.data;
};

export const getFieldControlKey = async (typeId) => {
  const response = await axios.get('edmu/device-type/key', {
    params: {
      'key-type': 'control',
      type: typeId,
      'include-info': true,
    },
  });
  return response.data;
};

export const getFieldControlStatus = async (deviceSerial) => {
  const response = await axios.get('edmu/device/control/loading-keys', {
    params: {
      'device-serial': deviceSerial,
    },
  });

  return response.data;
};

export const getBigQueryApiResult = (apiInfo) => {
  return new Promise((resolve) => {
    axios
      .get(`edmu/device/data/${apiInfo.deviceSerial}`, {
        params: {
          start: apiInfo.start,
          end: apiInfo.end,
          type: apiInfo.type,
          data: apiInfo.data,
          period: apiInfo.period,
          'process-type': apiInfo.processType,
          'time-zone': apiInfo.timezone,
          limit: apiInfo.limit,
          'sort-type': apiInfo.sortType,
          'include-null': apiInfo.includeNull,
          reverse: apiInfo.reverse,
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch(() => {
        resolve([]);
      });
  });
};

export const getFirestoreQueryResult = async (deviceInfo) => {
  if (!deviceInfo.parameter) return [];
  const dataTypeKeyList = deviceInfo.firebaseConfig;
  const indexesQueryReuslt = dataTypeKeyList.map((dataTypeKeyString) => {
    return query(
      collection(
        firebaseDatabase,
        `device/${deviceInfo.typeId}/${dataTypeKeyString}`,
      ),
      where('deviceId', '==', deviceInfo.serial),
      orderBy('timestamp', 'desc'),
      limit(10),
    );
  });
  return indexesQueryReuslt;
};

export const getDiviceByAll = async () => {
  const response = await axios.get(`edmu/device/list`);
  const allDeviceList = response.data;
  return allDeviceList;
};

export const getDeviceDetailInforApi = async (deviceId) => {
  const response = await axios.get(`edmu/device/list`, {
    params: {
      id: deviceId,
    },
  });
  return response.data?.[0]?.deviceDetailData;
};

export const getRouterSlaveDevicesByRouterSerial = async (routerSerial) => {
  const response = await axios.get(`edmu/device/list`, {
    params: {
      'router-serial': routerSerial,
    },
  });
  return response.data;
};

export const getBookmarkedDeviceByAll = async () => {
  const response = await axios.get(`edmu/device/list`, {
    params: {
      'is-bookmarked': true,
      'order-by': 'serial',
    },
  });
  return response.data;
};

export const addBookmarkDevice = async (deviceId) => {
  await axios.post(`edmu/device/bookmark`, null, {
    params: {
      id: deviceId,
    },
  });
};

export const deleteBookmarkDevice = async (deviceId) => {
  await axios.delete(`edmu/device/bookmark`, {
    params: {
      id: deviceId,
    },
  });
};

export const getDeviceLogData = async (timelineSetting, serial, timezone) => {
  const response = await axios.get(`edmu/log`, {
    params: {
      start: timelineSetting.selectedDate.start,
      end: timelineSetting.selectedDate.end,
      'alert-type': timelineSetting.checkedValues.join(','),
      'time-zone': timezone,
      'device-serial': serial,
      limit:
        timelineSetting.selectedDate.start && timelineSetting.selectedDate.end
          ? null
          : 20,
      'event-group': 'deviceConnectionEvent,deviceTimelineEvent',
    },
  });
  return response.data;
};

export const verifyManyDevices = async (deviceList) => {
  const res = await axios.post(`/edmu/device/many/verify`, {
    deviceData: deviceList,
  });
  return res;
};

export const createManyNewDeviceInfo = async (deviceList) => {
  const res = await axios.post(`/edmu/device/many`, {
    deviceData: deviceList,
  });
  return res;
};

export const getLogEvenTypes = async () => {
  try {
    const res = await axios.get(`/edmu/log/event/type`);
    return res.data;
  } catch (error) {
    return error;
  }
};

export const getAllDeviceTypes = async () => {
  try {
    const res = await axios.get(`/edmu/device-type`);
    return res.data;
  } catch (error) {
    return error;
  }
};

export const getAllDeviceTags = async () => {
  try {
    const res = await axios.get(`/edmu/tag`);
    return res.data;
  } catch (error) {
    return error;
  }
};

export const createNewDeviceTag = async (tagName) => {
  try {
    const res = await axios.post(`/edmu/tag`, {
      name: tagName,
    });
    return res.data.id;
  } catch (error) {
    return error;
  }
};

export const createNewDeviceInfo = async (newDeviceInfo) => {
  try {
    const res = await axios.post(`/edmu/device`, {
      serial: newDeviceInfo.serialNumber,
      locationId: newDeviceInfo.location.id,
      nickname: newDeviceInfo.name,
      tagId: newDeviceInfo.tagId,
      deviceModelId: newDeviceInfo.product.id,
    });
    return res;
  } catch (error) {
    return error;
  }
};

export const getStoredDeviceLocation = async () => {
  const response = await axios.get(`edmu/device/location`);
  return response.data;
};

export const createNewDeviceLocation = async (newDeviceLocationInfo) => {
  try {
    const res = await axios.post(`/edmu/device/location`, {
      name: newDeviceLocationInfo.name,
      address: newDeviceLocationInfo.address,
      addressDetail: newDeviceLocationInfo.addressDetail,
      zipCode: newDeviceLocationInfo.zipCode,
      lat: newDeviceLocationInfo.lat,
      lon: newDeviceLocationInfo.lon,
    });
    return res;
  } catch (error) {
    return error;
  }
};

export const updateDeviceLocation = async (newLocationInfo) => {
  try {
    const res = await axios.patch(
      `/edmu/device/location?id=${newLocationInfo.id}`,
      {
        address: newLocationInfo.address,
        addressDetail: newLocationInfo.addressDetail,
        zipCode: newLocationInfo.zipCode,
      },
    );
    return res;
  } catch (error) {
    return error;
  }
};

export const deleteDevice = async (deviceId, companyPassword) => {
  await axios.post(`/edmu/device/delete?id=${deviceId}`, {
    password: companyPassword,
  });
};

export const updateDevice = async (modifyInfo) => {
  try {
    const key =
      modifyInfo.key === 'location.name' ? 'locationId' : modifyInfo.key;
    const res = await axios.patch(`/edmu/device?id=${modifyInfo.id}`, {
      [key]: modifyInfo.value,
    });
    return res;
  } catch (error) {
    return error;
  }
};

export const controlDevice = async (serial, password, controlData) => {
  try {
    const res = await axios.post(`/edmu/device/control`, {
      serial: serial,
      password: password,
      controlData: controlData,
    });
    return res;
  } catch (error) {
    return error;
  }
};
export const getDeviceConnection = async (deviceSerial) => {
  try {
    const response = await axios.get(`edmu/device/connection/${deviceSerial}`);
    const commStatus = response.data;
    return commStatus;
  } catch (error) {
    return error;
  }
};

export const getDevicesSensors = async (typeId) => {
  const response = await axios.get('edmu/device-type/key', {
    params: {
      'key-type': 'sensors',
      type: typeId,
      'include-info': false,
      'graph-type': 'multiAxis',
    },
  });
  return response.data;
};

export const getWebsocketEventByAll = async (deviceSerial) => {
  const response = await axios.get(`edmu/device/control/loading-keys`, {
    params: {
      'device-serial': deviceSerial,
    },
  });
  return response.data;
};

export const postDeviceDetailInforApi = async (newInfor) => {
  const response = await axios.post(`edmu/device/detail-info`, {
    deviceId: newInfor.deviceId,
    detailData: newInfor.detailData,
  });
  return response;
};

export const patchDeviceDetailInfoApi = async (detailInfo) => {
  const response = await axios.patch(`edmu/device/detail-info`, {
    deviceId: detailInfo.id,
    detailData: detailInfo.data,
  });
  return response.data;
};
