import { makeAutoObservable, runInAction } from 'mobx';
import {
  postNewMaintenanceLog,
  getAllMaintenanceLog,
  getMaintenanceLogById,
  patchMaintenanceLog,
  deleteMaintenanceLog,
  postMaintenanceImage,
  deleteMaintenanceImage,
} from 'src/api/maintenance';

class maintenanceStore {
  accountStore;

  alertStore;

  maintenanceHistories = [];

  maintenanceInfo = {
    id: null,
    userId: null,
    title: null,
    deviceId: null,
    deviceData: {
      id: null,
      nickname: null,
      serialNumber: null,
      location: {
        id: null,
        name: null,
        type: null,
        address: null,
        addressDetail: null,
        lat: null,
        lon: null,
        zipCode: null,
      },
      type: {
        id: null,
        name: null,
      },
    },
    reporter: {
      id: null,
      name: null,
      email: null,
      phone: null,
      countrtCode: null,
      code: null,
    },
    worker: {
      id: null,
      name: null,
      email: null,
      phone: null,
      countrtCode: null,
      code: null,
    },
    serviceType: null,
    reportDate: null,
    dispatchDate: null,
    issueDescription: null,
    actionTaken: null,
    description: null,
    images: [],
  };

  startIndex = 0;

  endIndex = 1;

  selectedReportDate = [null, null];

  selectedDispatchDate = [null, null];

  constructor({ accountStore, alertStore }) {
    makeAutoObservable(this);
    this.accountStore = accountStore;
    this.alertStore = alertStore;
  }

  initializeMaintenanceInfo = () => {
    this.maintenanceInfo = {
      id: null,
      userId: null,
      title: null,
      deviceId: null,
      deviceData: {
        id: null,
        nickname: null,
        serialNumber: null,
        location: {
          id: null,
          name: null,
          type: null,
          address: null,
          addressDetail: null,
          lat: null,
          lon: null,
          zipCode: null,
        },
        type: {
          id: null,
          name: null,
        },
      },
      reporter: {
        id: null,
        name: null,
        email: null,
        phone: null,
        countrtCode: null,
        code: null,
      },
      worker: {
        id: null,
        name: null,
        email: null,
        phone: null,
        countrtCode: null,
        code: null,
      },
      serviceType: null,
      reportDate: null,
      dispatchDate: null,
      issueDescription: null,
      actionTaken: null,
      description: null,
      images: [],
    };
  };

  createNewMaintenanceLog = async (newLog) => {
    try {
      const id = await postNewMaintenanceLog(newLog).then((res) => {
        this.alertStore.setAlertOpen(
          'success',
          '새로운 수리 이력 추가가 완료되었습니다.',
        );
        if (res.code) throw res;
        return true;
      });
      return id;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return error;
    }
  };

  getMaintenanceLog = async (requestInfor) => {
    try {
      let res;
      if (requestInfor?.logId) {
        res = await getMaintenanceLogById({
          ...requestInfor,
          timeZone: this.accountStore.account.timeZone,
        });
        runInAction(() => {
          this.maintenanceInfo = res?.[0];
        });
      } else {
        res = await getAllMaintenanceLog({
          ...requestInfor,
          timeZone: this.accountStore.account.timeZone,
        });
        runInAction(() => {
          this.maintenanceHistories = res;
        });
      }
      return res;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return false;
    }
  };

  updateMaintenanceLog = async (modifyInfo) => {
    try {
      await patchMaintenanceLog({
        ...modifyInfo,
        timeZone: this.accountStore.account.timeZone,
      }).then((res) => {
        if (res) {
          this.getMaintenanceLog({
            reportStartDate: this.selectedReportDate[this.startIndex],
            reportEndDate: this.selectedReportDate[this.endIndex],
            dispatchStartDate: this.selectedDispatchDate[this.startIndex],
            dispatchEndDate: this.selectedDispatchDate[this.endIndex],
          });
          this.alertStore.setAlertOpen('success', '이력 수정이 완료되었습니다');
        }
      });
      return true;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return false;
    }
  };

  deleteMaintenanceLogById = async (selectedMaintenanceLogs) => {
    try {
      const deleteMaintenanceLogs = selectedMaintenanceLogs.map(
        (manufacturerInfo) => {
          return deleteMaintenanceLog(manufacturerInfo.id);
        },
      );
      return Promise.all(deleteMaintenanceLogs)
        .then(() => {
          this.getMaintenanceLog();
          this.alertStore.setAlertOpen(
            'success',
            `수리 이력 삭제를 완료했습니다.`,
          );
          return true;
        })
        .catch((error) => {
          this.alertStore.setAlertOpen('error', error);
          return false;
        });
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return false;
    }
  };

  createNewMaintenanceImage = async (logInfor) => {
    try {
      const id = await postMaintenanceImage(logInfor).then((res) => {
        this.alertStore.setAlertOpen('success', '사진 추가가 완료되었습니다');
        if (res.code) throw res;
        return true;
      });
      return id;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return error;
    }
  };

  deleteMaintenanceImageById = async (selectedMaintenanceImages) => {
    try {
      const deleteMaintenanceImages = selectedMaintenanceImages.map(
        (imageInfo) => {
          return deleteMaintenanceImage(imageInfo.id);
        },
      );
      return Promise.all(deleteMaintenanceImages)
        .then(() => {
          // this.getMaintenanceLog();
          this.alertStore.setAlertOpen('success', `사진 삭제를 완료했습니다`);
          return true;
        })
        .catch((error) => {
          this.alertStore.setAlertOpen('error', error);
          return false;
        });
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return false;
    }
  };

  setSelectedReportDate = (date) => {
    this.selectedReportDate = date;
  };

  setSelectedDispatchDate = (date) => {
    this.selectedDispatchDate = date;
  };
}

export default maintenanceStore;
