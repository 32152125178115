import RouterTwoToneIcon from '@mui/icons-material/RouterTwoTone';
import KitchenIcon from '@mui/icons-material/Kitchen';
import SolarPowerIcon from '@mui/icons-material/SolarPower';
import WindPowerIcon from '@mui/icons-material/WindPower';
import HeatPumpIcon from '@mui/icons-material/HeatPump';
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
import RouterIcon from '@mui/icons-material/Router';
import ThermostatIcon from '@mui/icons-material/Thermostat';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import DoDisturbAltIcon from '@mui/icons-material/DoDisturbAlt';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import EvStationIcon from '@mui/icons-material/EvStation';
import DevicesOtherIcon from '@mui/icons-material/DevicesOther';
import Co2Icon from '@mui/icons-material/Co2';

export const DeviceIconList = {
  라우터: <RouterTwoToneIcon fontSize="large" />,
  냉장고: <KitchenIcon fontSize="large" />,
  태양광: <SolarPowerIcon fontSize="large" />,
  풍력: <WindPowerIcon fontSize="large" />,
  온열기: <HeatPumpIcon fontSize="large" />,
  전기: <ElectricBoltIcon fontSize="large" />,

  'one-phase-solar': <SolarPowerIcon fontSize="large" />,
  'three-phase-solar': <SolarPowerIcon fontSize="large" />,
  'solar-thermal': <SolarPowerIcon fontSize="large" />,
  'solar-water-heater ': <SolarPowerIcon fontSize="large" />,
  geothermal: <SolarPowerIcon fontSize="large" />,
  wind: <WindPowerIcon fontSize="large" />,
  'fuel-cell': <SolarPowerIcon fontSize="large" />,
  ess: <SolarPowerIcon fontSize="large" />,
  router: <RouterIcon fontSize="large" />,
  'temperature-controller': <ThermostatIcon fontSize="large" />,
  'electric-vehicle-charger': <EvStationIcon fontSize="large" />,
  thermohygrometer: <ThermostatIcon fontSize="large" />,
  default: <DevicesOtherIcon fontSize="large" />,
  fs600r: <Co2Icon fontSize="large" />,

  AccessTime: <AccessTimeIcon fontSize="large" />,
  Thermostat: <ThermostatIcon fontSize="large" />,
  AcUnit: <AcUnitIcon fontSize="large" />,
  Restart: <RestartAltIcon fontSize="large" />,
  StartRequest: <PlayCircleOutlineIcon fontSize="large" />,
  EndRequest: <PauseCircleOutlineIcon fontSize="large" />,
  Power: <PowerSettingsNewIcon fontSize="large" />,
  Default: <DoDisturbAltIcon fontSize="large" />,
};

export default DeviceIconList;
