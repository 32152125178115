import CustomScrollbar from 'src/components/CustomScrollbar';
import { Link } from 'react-router-dom';
import { Box, Divider, Chip, styled } from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import SidebarMenu from './SidebarMenu';
import SidebarFooter from './SidebarFooter';
import Logo from 'src/components/Logo/index';
import { SidebarWrapper, LogoWrapper } from '../style';
import { useStore } from 'src/mobx-store';
import { observer } from 'mobx-react-lite';

const drawerWidth = 290;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `0px`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

const Sidebar = observer(() => {
  const { utilStore } = useStore();
  const { sidebarOpen } = utilStore;

  return (
    <Drawer
      className="shadow_effect"
      open={sidebarOpen}
      variant="permanent"
      elevation={9}
    >
      <SidebarWrapper className="sidebar_color">
        <CustomScrollbar>
          <LogoWrapper sidebarOpen={sidebarOpen}>
            <Box className="logo_box">
              <Link to="/">
                <Chip label={`V.${process.env.REACT_APP_VERSION}`} />
                {sidebarOpen && <Logo forceLightLogo />}
              </Link>
            </Box>
          </LogoWrapper>
          <Divider className="menu_divider divider" />
          <SidebarMenu />
        </CustomScrollbar>
        <Divider className="divider" />
        <SidebarFooter />
      </SidebarWrapper>
    </Drawer>
  );
});

export default Sidebar;
