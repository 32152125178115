import { LogoWrapper } from './style';
import { Box, Chip } from '@mui/material';
import Logo from 'src/components/Logo/index';
import { LinearProgress } from '@mui/material';

function AppInit() {
  return (
    <Box
      sx={{
        position: 'fixed',
        left: 0,
        top: 0,
        bottom: 0,
        right: 0,
        width: '100%',
        height: '100%',
      }}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <LogoWrapper>
        <Box display="flex" alignItems="end" justifyContent="end">
          <Chip label={`V.${process.env.REACT_APP_VERSION}`} />
        </Box>
        <Box className="logo_box">
          <Logo forceDarkLogo />
        </Box>
        <LinearProgress />
      </LogoWrapper>
    </Box>
  );
}

export default AppInit;
