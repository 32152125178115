import moment from 'moment';
import 'moment/min/locales';
import i18next from 'i18next';
import { getStore } from 'src/mobx-store';

export const convertTimestampToString = (date, options = {}) => {
  const { accountStore } = getStore();
  const { account } = accountStore;
  const { timeOnly = false, dateOnly = false, fixUTC = false } = options;

  if (date) {
    const dateObject = new Date(date);
    const year = fixUTC
      ? dateObject.getUTCFullYear()
      : dateObject.getFullYear();
    const month = (
      fixUTC ? dateObject.getUTCMonth() : dateObject.getMonth() + 1
    )
      .toString()
      .padStart(2, '0');
    const day = (fixUTC ? dateObject.getUTCDate() : dateObject.getDate())
      .toString()
      .padStart(2, '0');
    const hours = (fixUTC ? dateObject.getUTCHours() : dateObject.getHours())
      .toString()
      .padStart(2, '0');
    const minutes = (
      fixUTC ? dateObject.getUTCMinutes() : dateObject.getMinutes()
    )
      .toString()
      .padStart(2, '0');

    const accountDateFormat = account.dateFormat.string.split(
      account.dateFormat.delimiter,
    );
    let dateFormatResult = '';
    accountDateFormat.forEach((eachFormatData, index) => {
      switch (eachFormatData) {
        case 'YYYY':
          dateFormatResult = dateFormatResult.concat(
            year,
            index === accountDateFormat.length - 1
              ? ''
              : account.dateFormat.delimiter,
          );
          break;

        case 'MM':
          dateFormatResult = dateFormatResult.concat(
            month,
            index === accountDateFormat.length - 1
              ? ''
              : account.dateFormat.delimiter,
          );

          break;
        case 'DD':
          dateFormatResult = dateFormatResult.concat(
            day,
            index === accountDateFormat.length - 1
              ? ''
              : account.dateFormat.delimiter,
          );
          break;

        default:
          break;
      }
    });

    if (timeOnly) {
      return `${hours}:${minutes}`;
    }

    if (dateOnly) {
      return dateFormatResult;
    }
    return `${dateFormatResult} ${hours}:${minutes}`;
  }

  return '';
};

export const convertTimeFromSeconds = (time, t) => {
  if (time) {
    const days = Math.floor(time / (60 * 24));
    const remainingValue = time % (60 * 24);
    const hours = Math.floor(remainingValue / 60);
    const minutes = remainingValue % 60;

    let timeString = '';

    if (days > 0) {
      timeString += `${days} ${t('단위_일')} `;
    }

    if (hours > 0) {
      timeString += `${hours} ${t('단위_시간')} `;
    }

    if (minutes > 0) {
      timeString += `${minutes} ${t('단위_분')}`;
    }

    return timeString.trim();
  }
  return null;
};

export const getTodayTimeStamp = () => {
  let date = new Date();
  let todayTimeStamp = new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
  );

  return Math.floor(todayTimeStamp / 1000);
};

export const convertDateObjectToTimestamp = (dateString, options = {}) => {
  const { fixUTC = false } = options;
  let date;
  if (fixUTC) {
    const tempDate = new Date(dateString);
    date = new Date(
      Date.UTC(
        tempDate.getFullYear(),
        tempDate.getMonth(),
        tempDate.getDate(),
        tempDate.getHours(),
        tempDate.getMinutes(),
      ),
    );
  } else {
    date = new Date(dateString);
  }
  return Math.floor(date.getTime() / 1000);
};

export const convertUTCToLocalTime = (utcTimestamp) => {
  const utcDate = new Date(utcTimestamp * 1000);

  const localDate = new Date(
    utcDate.getUTCFullYear(),
    utcDate.getUTCMonth(),
    utcDate.getUTCDate(),
    utcDate.getUTCHours(),
    utcDate.getUTCMinutes(),
    utcDate.getUTCSeconds(),
  );

  return localDate;
};

export const formatToAllFromTimestamp = (
  timestamp,
  unit = 's',
  hourFormat = '12',
  dateOnly = false,
) => {
  const { accountStore } = getStore();
  const { account } = accountStore;

  if (timestamp) {
    const time = unit === 's' ? timestamp * 1000 : timestamp;
    return moment(time)
      .locale(i18next.language)
      .tz(account.timeZone)
      .format(
        dateOnly
          ? account.dateFormat.string || 'YYYY-MM-DD'
          : i18next.t(`날짜 및 시간 표시 ${hourFormat}시계`, {
              dateFormatString:
                account.dateFormat.string || 'YYYY-MM-DD HH:mm:ss',
            }),
      );
  }
  return i18next.t('데이터 없음');
};

export const formatToTimeFromTimestamp = (timestamp, t) => {
  if (timestamp) {
    return moment(timestamp * 1000).format('hh:mm A');
  }
  return t('데이터 없음');
};

export const getDatePickerDateFormat = () => {
  const { accountStore } = getStore();
  const { account } = accountStore;

  return (
    account.dateFormat.string
      .replaceAll('YYYY', 'yyyy')
      .replaceAll('DD', 'dd') || 'yyyy-MM-dd'
  );
};
